export interface defaultStateType {
  login_state: { state: string; user: string };

  product: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [];
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID: number | null;
  };
  customer: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [];
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID: number | null;
  };
  invoice: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [];
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID: number | null;
    invoiceNo: object | null;
  };
  quotation: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [];
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID: number | null;
    quotationNo: object | null;
  };
  dnote: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [];
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID: number | null;
    dnoteNo: object | null;
  };
  company: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [];
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID: number | null;
  };
  file: {
    pageSize?: number;
    pageNo?: number;
    searchField?: string | [];
    searchContent?: string | [];
    between?: [];
    conditionType?: "and" | "or";
    queryType?: "like" | "=";
    orderBy?: string;
    sortBy?: "ASC" | "DESC";
    currentID: number | null;
  };
  [propname: string]: any;
}

export const defaultState: defaultStateType = {
  login_state: { state: "loading", user: "" },
  product: {
    pageSize: 8,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: [],
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    currentID: null,
  },
  customer: {
    pageSize: 8,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: [],
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    currentID: null,
  },
  invoice: {
    pageSize: 8,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: [],
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    currentID: null,
    invoiceNo: { type: "auto", initNo: 0 },
  },
  quotation: {
    pageSize: 8,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: [],
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    currentID: null,
    quotationNo: { type: "auto", initNo: 0 },
  },
  dnote: {
    pageSize: 8,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: [],
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    currentID: null,
    dnoteNo: { type: "auto", initNo: 0 },
  },
  company: {
    pageSize: 8,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: [],
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    currentID: null,
  },
  file: {
    pageSize: 8,
    pageNo: 1,
    searchField: "",
    searchContent: "",
    between: [],
    conditionType: "and",
    queryType: "like",
    orderBy: "id",
    sortBy: "DESC",
    currentID: null,
  },
  test: { test1: 0, test2: 0 },
}; // checking/true/false
