import React, { useEffect } from "react";
import { Spin } from "antd";
import axios from "axios";
import { setLoginStateAction } from "../Reducers/login_reducer";
import { useStateContext } from "../Store/StateProvider";
import { useNavigate } from "react-router-dom";

export default function Loading() {
  let navigate = useNavigate();

  // let [state, dispatch] = useStateContext();
  // let navigate = useNavigate();

  // useEffect(() => {
  //   console.log("Layout effect check login state.");
  //   //login state is object {state:'true:false,loading,error',user:'admin'}
  //   axios
  //     .get("/api/check_login")
  //     .then((v) => {
  //       if (v.status === 200) {
  //         if (v.data.success === true) {
  //           dispatch(setLoginStateAction({ state: "true", user: v.data.data }));

  //           console.log("Layout page dispatch setLoginStateAction");
  //         } else {
  //           dispatch(setLoginStateAction({ state: "false" }));
  //           navigate("/login");
  //           console.log("2");
  //         }
  //       } else {
  //         dispatch(setLoginStateAction({ state: "error" }));
  //         navigate("/error");
  //       }
  //     })
  //     .catch((e) => {
  //       dispatch(setLoginStateAction({ state: "error" }));
  //       navigate("/error");
  //     });
  // }, []);

 useEffect(()=>{
   setTimeout(() => {
     navigate('/main')
   }, 3000);
 })

  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        margin: "0 auto",
        textAlign: "center",
        paddingTop: "10%",
      }}
    >
      <Spin tip="Loading..." size="large" />
    </div>
  );
}

function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
