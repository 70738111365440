import React, { useEffect } from "react";
import {
  useNavigate,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { useStateContext } from "../Store/StateProvider";

import { setLoginStateAction } from "../Reducers/login_reducer";

const Layout = (props: any) => {
  let [state, dispatch] = useStateContext();
  let navigate = useNavigate();
  let location = useLocation();
  const [searchParams] = useSearchParams();
  //fist time check

  console.log("Layout effect.");
  console.log("layout search."+searchParams.get('redirect'));
  useEffect(() => {
    console.log("Layout effect check login state.");
    //login state is object {state:'true:false,loading,error',user:'admin'}
    axios
      .get("/api/check_login")
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success === true) {
            dispatch(setLoginStateAction({ state: "true", user: v.data.data }));

            console.log("Layout page dispatch setLoginStateAction");
          } else {
            dispatch(setLoginStateAction({ state: "false" }));
            navigate("/login");
            console.log("2");
          }
        } else {
          dispatch(setLoginStateAction({ state: "error" }));
          navigate("/error");
        }
      })
      .catch((e) => {
        dispatch(setLoginStateAction({ state: "error" }));
        // props.history.push("/error");
        navigate("/error");
      });
  }, [location.pathname, dispatch, navigate]);

  // change login state and redirect page.
  useEffect(() => {
    console.log("Layout effect switch UI");
    switch (state.login_state.state) {
      case "loading":
        // props.history.push("/loading");
        navigate("/loading");
        break;
      case "false":
        //  props.history.push("/login");
        navigate("/login");
        break;
      case "true":
        // props.history.push("/main");
        //navigate("/main");
        if (searchParams.get("redirect")) {
          // redirect url to from url parm 'redirect'
          navigate(searchParams.get("redirect") as string)
        } else {
          navigate(
            location.pathname === "/login"
              ? "/main"
              : location.pathname === "/"
              ? "/main"
              : location.pathname + location.search
          );
          //navigate(location.pathname==='/'?'/main':location.pathname)
        }
        break;
      case "error":
        //props.history.push("/error");
        navigate("/error");
        break;
      default:
        //  props.history.push("/loading");
        navigate("/loading");
        break;
    }
  }, [state.login_state.state]);

  return <Outlet />;
};

export default Layout;
