import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Tabs,
  Tooltip,
  Popconfirm,
  Alert,
  Dropdown,
  Menu,
  message,
  Modal,
} from "antd";
import {
  CheckOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  CopyOutlined,
  PauseOutlined,
  PlusOutlined,
  CaretRightOutlined,
  DownOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { getDataForTable } from "../../Utils/getDataForTable";
import "./style.css";
import { useStateContext } from "../../Store/StateProvider";
import { reloadtAction as producReloadtAction } from "../../Reducers/product_reducer";
import { useNavigate } from "react-router-dom";

export default function ProductsDetail(props: any) {
  let [data, setData] = useState([]);
  let [pageTotal, setPageTotal] = useState();
  let [ids, setIds] = useState([]);

  let [state, dispatch] = useStateContext();

  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    currentID,
  ] = Object.values(state.product);

  let defaultRecord: {
    id: number | null;
    type: string;
    name: string;
    description: string;
    unit: string;
    price: number | null;
    enabled: boolean | null;
  } = {
    id: null,
    type: "",
    name: "",
    description: "",
    unit: "",
    price: null,
    enabled: null,
  };

  let [record, setRecord] = useState(defaultRecord);
  let netvigate=useNavigate()

  const fetchTableData = useMemo(
    () => () =>
      getDataForTable(
        {
          pageSize,
          pageNo,
          searchField,
          searchContent,
          between,
          conditionType,
          queryType,
          orderBy,
          sortBy,
        },
        setData,
        setPageTotal,
        "/api/products",
        "GET"
      ),
    [
      pageSize,
      pageNo,
      searchField,
      searchContent,
      between,
      conditionType,
      queryType,
      orderBy,
      sortBy,
    ]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  // call details.
  useEffect(() => {
    axios
      .get("/api/products", {
        params: { searchField: "id", searchContent: currentID },
      })
      .then((v) => v.data)
      .then((v) => {
        if (v.success) {
          setRecord({ ...v.data[0][0] });
        } else {
          Modal.error({ title: "Server internal error." });
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }, [currentID]);

  // Delete user
  function deleteRecord(id: any, bulk: boolean = false) {
    axios
      .delete(bulk ? "/api/product_bulk_delete" : "/api/product", {
        data: { id: id },
      })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            fetchTableData();
            setRecord({
              id: null,
              type: "",
              name: "",
              description: "",
              unit: "",
              price: null,
              enabled: null,
            });
            setIds([]);
           // message.success("Delete success.");
           netvigate('/main/product')
          } else {
            Modal.error({ title: "Error", content: v.data.msg });
          }
        } else {
          Modal.error({ title: "Server error" });
        }
      })
      .catch((e) => {
        Modal.error({ title: "network error" });
      });
  }

  function disEnProduct(id: any, enabled: boolean, isBulk: boolean = false) {
    axios
      .put(isBulk ? "/api/product_bulk_disable" : "/api/product", {
        id: id,
        enabled: enabled,
      })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            setRecord({ ...record, enabled: enabled });
            fetchTableData();
            setIds([]);
            message.success("Action success.");
          } else {
            Modal.error({
              title: "error.",
              content: JSON.stringify(v.data.msg),
            });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  function tableChange(pagination: any, filters: any, sorter: any) {
    // handle table sort.----------------

    let orderby: string = "";
    let order: any = "DESC";
    if (sorter.column) {
      orderby = sorter.field;
      order = sorter.order === "descend" ? "DESC" : "ASC";
    } else {
      orderby = "id";
      order = "DESC";
    }

    // end handel table sort--------------^
    dispatch(
      producReloadtAction({
        ...state.product,
        pageNo: pagination.current,
        orderBy: orderby,
        sortBy: order,
        pageSize: pagination.pageSize,
      })
    );
  }

  const action_menu = (
    <Menu>
      <Menu.Item
        key="delete"
        onClick={() => {
          Modal.confirm({
            title: "Are you confirm to delete?",
            onOk() {
              deleteRecord(ids, true);
            },
          });
        }}
      >
        <DeleteOutlined />
        Delete
      </Menu.Item>
      <Menu.Item key="disable" onClick={() => disEnProduct(ids, false, true)}>
        <PauseOutlined />
        Disable
      </Menu.Item>
      <Menu.Item key="enable" onClick={() => disEnProduct(ids, true, true)}>
        <CaretRightOutlined />
        Enable
      </Menu.Item>
    </Menu>
  );

  let columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      sortOrder:
        orderBy === "name"
          ? sortBy === "ASC"
            ? "ascend"
            : ("descend" as "descend" | "ascend" | null | undefined)
          : null,
      sorter: true,
      ellipsis: true,
      render: (text: any, record: any, index: any) => {
        return <span style={{ color: "#1890ff" }}>{text}</span>;
      },
    },
  ];
  return (
    <>
      <div style={{ display: "table", width: "100%" }}>
        <div style={{ width: 300, display: "table-cell" }}>
          {searchField && (
            <Alert
              message="Search Criteria: Name, Description.  Close this message to cancel search mode."
              type="success"
              closable
              afterClose={() =>
                dispatch(
                  producReloadtAction({
                    ...state.product,
                    searchField: "",
                    searchContent: "",
                  })
                )
              }
            />
          )}

          {ids.length > 0 && (
            <div
              style={{
                display: "table",
                width: "100%",
              }}
            >
              <div style={{ display: "table-cell" }}>
                <Alert
                  message={`Select ${ids.length} Item(s). `}
                  type="success"
                  closable
                  onClose={() => {
                    setIds([]);
                  }}
                />
              </div>
              <div style={{ display: "table-cell", width: "20%" }}>
                <Dropdown overlay={action_menu}>
                  <Button style={{ height: 40 }}>
                    Actions <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          )}
          <Table
            rowKey="id"
            tableLayout="fixed"
            onRow={(record) => {
              return {
                onClick: (event) => {
                  dispatch(
                    producReloadtAction({
                      ...state.product,
                      currentID: record.id,
                    })
                  );
                  //setRecord({ ...record });
                },
                onMouseEnter: (event) => {
                  event.currentTarget.setAttribute("style", "cursor: pointer;");
                },
              };
            }}
            bordered
            columns={columns2}
            dataSource={data}
            pagination={{
              current: pageNo,
              total: pageTotal,
              pageSize: pageSize,
              size: "small",
              defaultPageSize: 8,
              showSizeChanger: true,
              pageSizeOptions: [
                "8",
                "16",
                "24",
                "30",
                "40",
                "50",
                "100",
                "200",
              ],
              // onShowSizeChange:(current, pageSize)=>dispatch(producReloadtAction({...state.product,pageSize:pageSize,pageNo:1}))
            }}
            onChange={tableChange}
            style={{ minHeight: 500, width: 300 }}
            rowClassName={(record, index) => {
              if (record.id === currentID) {
                return "my-table-row-selected";
              } else {
                return "";
              }
            }}
            rowSelection={{
              selections: true,
              selectedRowKeys: ids,
              onChange: (selectKeys) => {
                setIds(selectKeys as []);
              },
            }}
            scroll={pageSize <= 8 ? undefined : { y: 500 }}
          ></Table>
        </div>
        <div style={{ display: "table-cell" }}>
          <div>
            <div style={{ height: 56, display: "table", width: "100%" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  paddingLeft: 16,
                  width: "50%",
                  fontWeight: "bold",
                }}
              >
                {record.name}
              </div>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "right",
                }}
              >
                <Tooltip placement="bottom" title={"Add new item"}>
                  <Button
                    icon={<PlusOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={() => netvigate("/main/product/new")}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Edit this item"}>
                  <Button
                    icon={<EditOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      if (currentID === null) {
                        Modal.info({
                          title: "Error",
                          content: "Select one product first.",
                        });
                      } else {
                        netvigate("/main/product/edit");
                      }
                    }}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Copy this item"}>
                  <Button
                    icon={<CopyOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={() => netvigate("/main/product/new?copy")}
                  ></Button>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={
                    record.enabled ? "Disable this item" : "Enable this item"
                  }
                >
                  <Button
                    icon={record.enabled ? <StopOutlined /> : <CheckOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={() => disEnProduct(record.id, !record.enabled)}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Delete this item"}>
                  <Popconfirm
                    placement="top"
                    title="Are sure to delete this item?"
                    onConfirm={() => deleteRecord(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ marginRight: 16 }}
                    ></Button>
                  </Popconfirm>
                </Tooltip>
                <Tooltip placement="bottom" title={"Close"}>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => netvigate("/main/product")}
                  ></Button>
                </Tooltip>
              </div>
            </div>
            <div>
              <Tabs type="card">
                <Tabs.TabPane tab="Overview" key="1">
                  <div
                    style={{
                      display: "table",
                      width: "100%",
                      height: "300px",
                    }}
                  >
                    <div style={{ display: "table-row" }}>
                      <div
                        style={{
                          display: "table-cell",
                          width: 120,
                          paddingLeft: 28,
                          color: "#999999",
                        }}
                      >
                        Type:{" "}
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {" "}
                        {record.type}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div
                        style={{
                          display: "table-cell",
                          width: 120,
                          paddingLeft: 28,
                          color: "#999999",
                        }}
                      >
                        Name:{" "}
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {" "}
                        {record.name}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div
                        style={{
                          display: "table-cell",
                          width: 120,
                          paddingLeft: 28,
                          color: "#999999",
                        }}
                      >
                        Description:{" "}
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {" "}
                        {record.description}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div
                        style={{
                          display: "table-cell",
                          width: 120,
                          paddingLeft: 28,
                          color: "#999999",
                        }}
                      >
                        Price:{" "}
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {" "}
                        {record.price}
                      </div>
                    </div>
                    <div style={{ display: "table-row" }}>
                      <div
                        style={{
                          display: "table-cell",
                          width: 120,
                          paddingLeft: 28,
                          color: "#999999",
                        }}
                      >
                        Enabled:{" "}
                      </div>
                      <div style={{ display: "table-cell" }}>
                        {" "}
                        {record.enabled ? <CheckOutlined /> : <StopOutlined />}
                      </div>
                    </div>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="History" key="2">
                  <div style={{ display: "table-row" }}>
                    <div
                      style={{
                        display: "table-cell",
                        width: 120,
                        paddingLeft: 28,
                        color: "#999999",
                      }}
                    >
                      test:{" "}
                    </div>
                    <div style={{ display: "table-cell" }}> test</div>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
