import axios from "axios";
import qs from 'qs'
import { Modal, message } from 'antd';

// For Page use ant Table component and use useState.
// All Table page use defaultTableState for default state.

export interface defaultTableStateType {
  data: Array<any>,
  pagination: { current: number, pageSize: number, total: number },
  loading: boolean,
  modal: { show: boolean, url: string, title: string }
  searchField: string | string[],
  searchContent: string | string[],
  between: string[],
  conditionType: 'or' | 'and',
  queryType: '=' | 'like'
  orderBy: string,
  sortBy: 'ASC' | 'DESC',
  [key: string]: any
}

export const defaultTableState: defaultTableStateType =
{
  // Use useState(defaultTableState) to create page table search state for table
  data: [],
  pagination: { current: 1, pageSize: 8, total: 0 },
  loading: false,
  modal: { show: false, url: "", title: "" },
  searchField: "",
  searchContent: "",
  between: [],
  conditionType: 'and',
  queryType: '=',
  orderBy: '',
  sortBy: 'ASC'
}


export interface SearchParamsInterface {

  // Here use to for search parameter.

  pageSize?: number,
  pageNo?: number,
  searchField?: string,
  searchContent?: string ,
  between?: string,
  conditionType?:'and' | 'or',
  queryType?: 'like' | '=',
  orderBy?: string,
  sortBy?: 'ASC' | 'DESC'
}

export const tableStateToParms = (state: defaultTableStateType) => {
  return {
    pageSize: state.pagination.pageSize,
    pageNo: state.pagination.current,
    searchField: state.searchField,
    searchContent: state.searchContent,
    between: state.between,
    conditionType: state.conditionType,
    queryType: state.queryType,
    orderBy: state.orderBy,
    sortBy: state.sortBy
  }
}

export const getDataForTable = (
  params:any,
  setData: any,
  setPageTotal:any,
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',  
  cancelToken?: any
) => {
  axios({
    cancelToken: cancelToken,
    method: method,
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    // Use qs convert object to string as params 0=8234234@323
    params: params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),


  })
    .then(v => v.data)
    .then(v => {
      if (v.success) {
        setData(v.data[0])
        setPageTotal(v.data[1])
        if((v.data[0] as []).length===0){
          message.success("No data.");
        }
      }else{
        Modal.error({title:'Error',content:v.msg?.message})
      }
    });
};


