import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  Modal,
  message,
  Table,
  Select,
  DatePicker,
  Row,
  Col,
  Divider,
  Card,
  InputNumber,
  Tooltip,
  Space,
} from "antd";
import {
  CaretUpFilled,
  CaretDownFilled,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import moment from "moment";

import { useStateContext } from "../../Store/StateProvider";
import { reloadtAction as customerReloadtAction } from "../../Reducers/customer_reducer";
import { reloadtAction as invoiceReloadtAction } from "../../Reducers/invoice_reducer";
import { reloadtAction as productReloadtAction } from "../../Reducers/product_reducer";

import "./style.css";
import isArrayHasItems from "../../Utils/isArrayHasItem";
import notEmptyObject from "../../Utils/notEmptyObject";
import NewCustomer from "../Customer/NewCustomer";
import { useLocation, useNavigate } from "react-router-dom";
import NewProduct from "../Product/NewProduct";
import { verify } from "crypto";

export default function NewInvoice(props: any) {
  let [{ customer, invoice, quotation, product }, dispatch] = useStateContext();

  // invoiceNO for show header invoice only.
  let [invoiceNo, setInvoiceNo] = useState(null);
  let currentID = invoice.currentID;
  let netvigator = useNavigate();

  interface itemInterface {
    // items fiels

    //id for database field "sequence"
    sequence: number;
    product?: number;
    item_details: string;
    quantity: number;
    price: number;
    discount: number;
    discount_percent: boolean;
    amount: number;
    invoice: number;
  }
  let defaultItems = {
    //for add new item set default value.
    sequence: 1,
    quantity: 1,
    price: 0,
    discount_percent: true,
    discount: 0,
    amount: 0,
  } as itemInterface;

  // let [invoiceData, setInvoiceData] = useState(defaultInvoiceDate);

  // invoice items
  let [items, setItems] = useState([defaultItems]);
  let [showCellBorderRed, setShowCellBorderRed] = useState([{}]);
  let [companies, setCompanies] = useState([{} as any]);
  let [customers, setCustomers] = useState([{} as any]);
  let [products, setProducts] = useState([{} as any]);

  // current cs id and current info.
  let [cs, setCS] = useState({} as any);

  //  currenct billing address and shopping addresss.
  let [address, setAddress] = useState({} as any);

  // show customer modal
  let [showCusModal, setShowCusModal] = useState(false);
  let [customerAddType, setCustomerAddType] = useState("new");

  // show product modal
  let [showProModal, setShowProModal] = useState(false);
  let [productAddType, setProductAddType] = useState("new");
  let [itemIndex, setItemIndex] = useState();

  let location = useLocation();
  let isNew = location.pathname.indexOf("/new") > 0;
  let isCopy = (location.search === "?copy");

  const [form] = Form.useForm();

  // reset customer current ID.
  useEffect(() => {
    dispatch(
      customerReloadtAction({
        ...customer,
        currentID: "",
      })
    );
  }, []);

  useEffect(() => {
    getCompanies();
    getCustomers();
    getProducts();
  }, []);

  useEffect(() => {
    // useEffect get info for copy or edit.
    if (!isNew || isCopy)
      axios
        .get("/api/invoice", {
          params: { id: currentID },
        })
        .then((v) => v.data)
        .then((v) => {
          if (v.success) {
            //let d = moment(v.data.due_date);
            // v.data.due_date = d
            form.setFieldsValue({
              id: currentID,
              company: v.data.company.id,
              invoice_no: v.data.invoice_no,
              customer: v.data.customer.id,
              invoice_date:isCopy?moment(new Date()):moment(v.data.invoice_date),
              due_date: isCopy?moment(new Date()).add(1,'M'):moment(v.data.due_date),
              Salesperson: v.data.Salesperson,
              discription: v.data.discription,
              terms_conditions: v.data.terms_conditions,
              sub_total: v.data.sub_total,
              discount: v.data.discount,
              discount_percent: v.data.discount_percent,
              tax: v.data.tax,
              total: v.data.total,
              customer_notes: v.data.customer_notes,
              processed: v.data.processed,
            });

            // for customer add or edit .
            dispatch(
              customerReloadtAction({
                ...customer,
                currentID: v.data.customer.id,
              })
            );
            getCustomer(v.data.customer.id);

            location.search === "" && setInvoiceNo(v.data.invoice_no);
            setTimeout(() => {
              // set Time out for set billing_address and shipping_address.
              form.setFieldsValue({
                billing_address: v.data.billing_address.id,
                shipping_address: v.data.shipping_address.id,
              });
              setAddress({
                billing_address: v.data.billing_address,
                shipping_address: v.data.shipping_address,
              });

              // first time set items for copy or edit.
              setItems(v.data.items);
            }, 800);
          } else {
            Modal.error({ title: "Server internal error." });
          }
        })
        .catch((e) => {
          Modal.error({ title: "Network error." });
        });
  }, [currentID, form, isNew, location.search]);

  useEffect(() => {
    // useEffect get from quotation.
    if (location.search === "?quotation")
      axios
        .get("/api/quotation", {
          params: { id: quotation.currentID },
        })
        .then((v) => v.data)
        .then((v) => {
          if (v.success) {
            //let d = moment(v.data.due_date);
            // v.data.due_date = d
            form.setFieldsValue({
              id: currentID,
              company: v.data.company.id,
              quotation_no: v.data.quotation_no,
              customer: v.data.customer.id,
              // invoice_date: moment(v.data.invoice_date),
              //  due_date: moment(v.data.due_date),
              Salesperson: v.data.Salesperson,
              discription: v.data.discription,
              terms_conditions: v.data.terms_conditions,
              sub_total: v.data.sub_total,
              discount: v.data.discount,
              discount_percent: v.data.discount_percent,
              tax: v.data.tax,
              total: v.data.total,
              customer_notes: v.data.customer_notes,
              processed: v.data.processed,
            });

            // for customer add or edit .
            dispatch(
              customerReloadtAction({
                ...customer,
                currentID: v.data.customer.id,
              })
            );
            getCustomer(v.data.customer.id);

            //location.search === "" && setInvoiceNo(v.data.invoice_no);
            setTimeout(() => {
              // set Time out for set billing_address and shipping_address.
              form.setFieldsValue({
                billing_address: v.data.billing_address.id,
                shipping_address: v.data.shipping_address.id,
              });
              setAddress({
                billing_address: v.data.billing_address,
                shipping_address: v.data.shipping_address,
              });

              // first time set items for copy or edit.
              setItems(v.data.items);
            }, 800);
          } else {
            Modal.error({ title: "Server internal error." });
          }
        })
        .catch((e) => {
          Modal.error({ title: "Network error." });
        });
  }, []);

  function getCompanies(s: any = null, v: any = null) {
    // Company field get customers list.

    axios
      .get("/api/companies", {
        params: { searchField: s, searchContent: v, conditionType: "or" },
      })
      .then((v) => v.data)
      .then((v) => {
        if (v.success) {
          setCompanies(v.data[0]);
        } else {
          Modal.error({ title: "Server internal error." });
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  function getCustomers(s: any = null, v: any = null) {
    // For customer field get customers list.

    axios
      .get("/api/customers", {
        params: { searchField: s, searchContent: v },
      })
      .then((v) => v.data)
      .then((v) => {
        if (v.success) {
          setCustomers(v.data[0]);
        } else {
          Modal.error({ title: "Server internal error." });
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  function getCustomer(id: number | string, fn?: () => {}) {
    //  get one Customer details , for Billing Address input
    //
    // if(typeof id==="number"){
    //   id=id.toString()
    // }
    axios
      .get("/api/customer", {
        params: { id: id },
      })
      .then((v) => v.data)
      .then((v) => {
        if (v.success) {
          setCS(v.data);
          isArrayHasItems(v.data.addresses)
            ? form.setFieldsValue({
                billing_address: (v.data.addresses as Array<any>).find(
                  (i) => i.default_billing_address
                )?.id,
                shipping_address:
                  isArrayHasItems(v.data.addresses) &&
                  (v.data.addresses as Array<any>).find(
                    (i) => i.default_shipping_address
                  )?.id,
              })
            : form.setFieldsValue({
                billing_address: "",
                shipping_address: "",
              });

          setAddress({
            billing_address:
              isArrayHasItems(v.data.addresses) &&
              (v.data.addresses as Array<any>).find(
                (i) => i.default_billing_address
              ),
            shipping_address:
              isArrayHasItems(v.data.addresses) &&
              (v.data.addresses as Array<any>).find(
                (i) => i.default_shipping_address
              ),
          });
        } else {
          Modal.error({ title: "Server internal error." });
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }
  function getProducts(s: any = null, v: any = null) {
    // product field get products.

    axios
      .get("/api/products", {
        params: { searchField: s, searchContent: v },
      })
      .then((v) => v.data)
      .then((v) => {
        if (v.success) {
          setProducts(v.data[0]);
        } else {
          Modal.error({ title: "Server internal error." });
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  function showError(json: [any]) {
    showCellBorderRed = [];
    if (json.length > 0) {
      for (let i in json) {
        if (
          (json[i] as any).property === "addresses" ||
          (json[i] as any).property === "contacts"
        ) {
          for (let j in (json[i] as any).children) {
            for (let k in (json[i] as any).children[j].children) {
              showCellBorderRed.push(
                `${(json[i] as any).property}-${
                  (json[i] as any).children[j].property
                }-${(json[i] as any).children[j].children[k].property}`
              );
            }
          }
        }
      }
    }
    setShowCellBorderRed([...showCellBorderRed]);
  }

  function update(v: any) {
    // update invoice .
    verifyItemsBeforeSubmit(items, v);
    // items = items.filter((item) => Object.keys(item).length > 0);
    // setItems([...items]);

    v.items = items;
    axios
      .put("/api/invoice", v)
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            //Modal.info({ title: "Add user success" });
            netvigator(-1);
          } else {
            showError(v.data.msg);
            message.warning("Input data error.");
            // Modal.error({
            //   title: "Add Customer failed.",
            //   content: JSON.stringify(v.data.msg),
            // });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  function verifyItemsBeforeSubmit(item: Array<itemInterface>, form_v: any) {
    let reset_sequence = false; // for reset sequence no when deleted item.

    if (item.length > 0) {
      for (let i = 0; i < item.length; i++) {
        if (
          item[i].item_details === undefined ||
          item[i].item_details === null ||
          item[i].item_details.trim() === ""
        ) {
          // item_details empty delete this item.
          item.splice(i, 1);
          reset_sequence = true;
        }
      }
    }

    if (item.length > 0 && reset_sequence) {
      // After remove reset sequense no
      for (let i = 0; i < item.length; i++) {
        item[i].sequence = i + 1;
      }

      // count total
      form_v.sub_total = item.map((v) => v.amount).reduce((a, b) => a + b);

      if (form_v.discount_percent) {
        form_v.total =
          form_v.sub_total * (1 - form_v.discount * 0.01) + form_v.tax;
      } else {
        form_v.total = form_v.sub_total - form_v.discount + form_v.tax;
      }
      form_v.total = Number(form_v.total.toFixed(2));
    } else if ((item.length === 0 && reset_sequence)) {
      form_v.sub_total = 0;
      form_v.total = 0;
    }
    
    setItems([...item]); // fix update ui error.
  }

  function submitNew(v: any) {
    verifyItemsBeforeSubmit(items, v);
    v.invoice_date = v.invoice_date.format();
    v.due_date = v.due_date.format();

    // delete items.id prevent update items
    items.forEach((i: any) => {
      delete i.id;
    });

    // v.items is emty, need outsize items values
    v.items = items;

    // delete id prevent update
    delete v.id;
    axios
      .post("/api/invoice", v)
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            //Modal.info({ title: "Add user success" });
            dispatch(
              invoiceReloadtAction({
                ...invoice,
                orderBy: "id",
                sortBy: "DESC",
                searchField: "",
                searchContent: "",
                pageNo: 1,
              })
            );
            netvigator("../");
          } else {
            //showError(v.data.msg);
            message.warning("Input data error.");
            // Modal.error({
            // itle: "Add Customer failed.",
            // content: JSON.stringify(v.data.msg),
            // });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  function countAmount(item: itemInterface) {
    // count item amount  item is items[index]
    if (
      typeof item.quantity === "number" &&
      typeof item.price === "number" &&
      typeof item.discount === "number"
    ) {
      if (item.discount_percent) {
        item.amount = item.quantity * item.price * (1 - item.discount * 0.01);
      } else {
        item.amount = item.quantity * item.price - item.discount;
      }
      item.amount = Number(item.amount.toFixed(2));
    }
    countTotal();
  }

  function countTotal() {
    // count total

    if (items.length > 0) {
      form.setFieldsValue({
        sub_total: items.map((v) => v.amount).reduce((a, b) => a + b),
      });
    } else {
      form.setFieldsValue({
        sub_total: 0,
      });
    }

    let sub_total = form.getFieldValue("sub_total") as number;
    let tax = form.getFieldValue("tax") as number;
    let discount_percent = form.getFieldValue("discount_percent") as boolean;
    let discount = form.getFieldValue("discount") as number;
    let total: number = 0;

    if (discount_percent) {
      total = sub_total * (1 - discount * 0.01) + tax;
    } else {
      total = sub_total - discount + tax;
    }
    total = Number(total.toFixed(2));

    // count subtotal.
    form.setFieldsValue({ total: total });
  }

  let item_column = [
    { dataIndex: "sequence" },
    {
      title: "PRODUCT",
      dataIndex: "product",
      width: "35%",
      render: (text: any, record: any, index: any) => (
        <>
          <TextArea
            rows={4}
            onChange={(e) => {
              items[index].item_details = e.target.value;
              setItems([...items]);
            }}
            placeholder="Product Decsription"
            value={items[index].item_details}
          />
          <Row>
            <Col span={15}>
              <Select
                placeholder="Input keyword to filter product"
                style={{ width: "100%" }}
                showSearch
                filterOption={false}
                onSearch={(v) => {
                  delete items[index].product;
                  // setItems([...items]);
                  getProducts("name", v);
                }}
                onChange={(v) => {
                  let pro = products.find((i) => i.id === v);
                  if (pro) {
                    items[index].price = pro.price;
                    items[index].item_details = pro.name;
                    countAmount(items[index]);
                    items[index].product = v;
                    setItems([...items]);
                  }
                }}
                defaultActiveFirstOption={false}
                value={items[index].product}
              >
                {/* {items[index].product ? (
              <Select.Option
                key={items[index].product}
                value={items[index].product ?? ""}
              >
                {items[index].item_details}
              </Select.Option>
            ) : (
              products.map((d) => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))
            )} */}
                {products.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={9} style={{ textAlign: "right" }}>
              {" "}
              <Button
                style={{
                  marginBottom: 16,
                }}
                onClick={() => {
                  dispatch(
                    productReloadtAction({
                      ...product,
                      currentID: items[index].product,
                    })
                  );
                  setProductAddType(items[index].product ? "edit" : "new");
                  setShowProModal(true);
                  setItemIndex(index);
                }}
              >
                {items[index].product ? "Edit This Product" : "Add New Product"}
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      width: "15%",
      render: (text: any, record: any, index: any) => (
        <InputNumber
          defaultValue={1}
          value={record.quantity}
          onChange={(v) => {
            if (v <= 0) {
              v = 1;
            }
            items[index].quantity = v as number;
            countAmount(items[index]);
            setItems([...items]);
          }}
        ></InputNumber>
      ),
    },
    {
      title: "PRICE (HK$)",
      dataIndex: "price",
      width: "15%",
      render: (text: any, record: any, index: any) => (
        <InputNumber
          width={"100%"}
          value={record.price}
          defaultValue={1}
          onChange={(v) => {
            if (v < 0) {
              v = 0;
            }
            items[index].price = v as number;
            countAmount(items[index]);
            setItems([...items]);
          }}
        ></InputNumber>
      ),
    },
    {
      title: "DISCOUNT",
      dataIndex: "discount",
      width: "15%",
      render: (text: any, record: any, index: any) => {
        return (
          <Input.Group compact>
            <InputNumber
              value={record.discount}
              onChange={(v) => {
                if (v < 0) {
                  v = 0;
                }
                items[index].discount = v as number;
                countAmount(items[index]);
                setItems([...items]);
              }}
            ></InputNumber>
            <Select
              defaultValue="false"
              onChange={(v) => {
                let result: boolean = true;
                if (v === "false") {
                  result = false;
                }
                items[index].discount_percent = result;
                countAmount(items[index]);
                setItems([...items]);
              }}
            >
              <Select.Option key="1" value="true">
                %
              </Select.Option>
              <Select.Option key="2" value="false">
                $
              </Select.Option>
            </Select>
          </Input.Group>
        );
      },
    },

    {
      title: "AMOUNT",
      dataIndex: "amount",
      render: (text: any, record: any, index: any) => (
        <div>{record.amount}</div>
      ),
    },
    {
      title: "",

      render: (text: any, record: any, index: any) => (
        <Row>
          <Col>
            <Row>
              <Col>
                <Button
                  style={{ border: 0 }}
                  icon={<CaretUpFilled style={{ color: "green" }} />}
                  onClick={() => {
                    if (index > 0) {
                      let temp = items[index - 1];
                      items.splice(index - 1, 1, items[index]);
                      items.splice(index, 1, temp);
                      for (const i in items) {
                        items[Number(i)].sequence = Number(i) + 1;
                      }

                      setItems([...items]);
                    }
                  }}
                ></Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  style={{ border: 0 }}
                  icon={<CaretDownFilled style={{ color: "green" }} />}
                  onClick={() => {
                    if (index < items.length - 1) {
                      let temp = items[index];
                      items.splice(index, 1, items[index + 1]);
                      items.splice(index + 1, 1, temp);
                      for (const i in items) {
                        items[Number(i)].sequence = Number(i) + 1;
                      }
                      setItems([...items]);
                    }
                  }}
                ></Button>
              </Col>
            </Row>
          </Col>
          <Col style={{ display: "table-cell", marginLeft: 5 }}>
            <Row style={{ display: "inline-block" }}>
              <Col>
                <Tooltip placement="top" title={"Copy"}>
                  <Button
                    style={{ border: 0 }}
                    icon={<CopyOutlined style={{ color: "green" }} />}
                    onClick={() => {
                      let temp = { ...items[index] };
                      items.splice(items.length, 1, temp);
                      countTotal();

                      for (const i in items) {
                        items[Number(i)].sequence = Number(i) + 1;
                      }
                      setItems([...items]);
                    }}
                  ></Button>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip placement="top" title={"Delete"}>
                  <Button
                    style={{ border: 0 }}
                    icon={<DeleteOutlined style={{ color: "red" }} />}
                    onClick={() => {
                      items.splice(index, 1);
                      countTotal();
                      for (const i in items) {
                        items[Number(i)].sequence = Number(i) + 1;
                      }
                      setItems([...items]);
                    }}
                  ></Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      {/*Customer Modal */}
      <Modal
        visible={showCusModal}
        closable={true}
        destroyOnClose={true}
        onCancel={() => {
          setShowCusModal(false);
        }}
        width={800}
        footer={null}
      >
        <NewCustomer
          // location={{ search: "" }}
          closeModal={() => setShowCusModal(false)}
          form={form}
          getCustomers={(v: any) => getCustomers(v)}
          getCustomer={(v: any) => getCustomer(v)}
          // pass to customer new or edit
          customerAddType={customerAddType}
          // pass to customer is modal mode or not.
          modal_mode={true}
        ></NewCustomer>
      </Modal>

      {/*Product Modal */}
      <Modal
        visible={showProModal}
        closable={true}
        destroyOnClose={true}
        onCancel={() => {
          setShowProModal(false);
          getProducts();
        }}
        width={800}
        footer={null}
      >
        <NewProduct
          // location={{ search: "" }}
          closeModal={(v: number) => {
            setShowProModal(false);
            getProducts("id", v);
          }}
          items={items} // items
          index={itemIndex} //items index
          countAmount={countAmount}
          setItems={setItems}
          // pass to customer new or edit
          productAddType={productAddType}
          // pass to customer is modal mode or not.
          modal_mode={true}
        ></NewProduct>
      </Modal>
      <div
        style={{ textAlign: "center", fontWeight: "bold", marginBottom: 10 }}
      >
        {invoiceNo}
      </div>
      <Form
        form={form}
        labelCol={{ span: 3, style: { textAlign: "left" } }}
        wrapperCol={{ span: 19 }}
        initialValues={{
          processed: false,
          invoice_date: moment(),
          due_date: moment().add(1, "M"),
          tax: 0,
          discount_percent: false,
          discount: 0,
          sub_total: 0,
          terms_conditions: "Payment terms:\nC.O.D.\n",
        }}
        onFinish={(v) => {
          isNew ? submitNew(v) : update(v);
        }}
      >
        <Form.Item name="id" label="id" style={{ display: "none" }}>
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="quotation_no"
          label="quotation_no"
          style={{ display: "none" }}
        >
          <Input></Input>
        </Form.Item>
        <Row>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3 }}
              labelAlign="left"
              label="Company"
              name="company"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={false}
                onSearch={(v) => {
                  getCompanies(["company_name_en", "company_name_ch"], [v, v]);
                }}
                defaultActiveFirstOption={false}
                onChange={(v: any) => {
                  let t = companies.filter((i: any) => i.id === v)[0];
                  form.setFieldsValue({
                    terms_conditions: t.others
                      ? "Payment terms:\nC.O.D.\n" + t.others
                      : "Payment terms:\nC.O.D.\n",
                  });
                }}
              >
                {companies.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.company_name_en}
                    <br /> {d.company_name_ch}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              labelCol={{ span: 6 }}
              labelAlign="left"
              label="Customer"
              name="customer"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                filterOption={false}
                onSearch={(v) => {
                  getCustomers("name", v);
                }}
                onChange={(v) => {
                  getCustomer(v);
                  dispatch(
                    customerReloadtAction({
                      ...customer,
                      currentID: v,
                    })
                  );
                }}
                defaultActiveFirstOption={false}
              >
                {customers.map((d) => (
                  <Select.Option key={d.id} value={d.id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button
              onClick={() => {
                setCustomerAddType("new");
                setShowCusModal(true);
              }}
            >
              Add
            </Button>
            <Button
              disabled={customer.currentID ? false : true}
              onClick={() => {
                setCustomerAddType("edit");
                setShowCusModal(true);
              }}
            >
              Edit
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {" "}
            <Form.Item
              labelCol={{ span: 6 }}
              labelAlign="left"
              label="Billing Address"
              name="billing_address"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(v) =>
                  isArrayHasItems(cs.addresses) &&
                  setAddress({
                    ...address,
                    billing_address: (cs.addresses as Array<any>).find(
                      (i) => i.id === v
                    ),
                  })
                }
                defaultActiveFirstOption={false}
              >
                {isArrayHasItems(cs.addresses) &&
                  (cs.addresses as Array<any>).map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      <p>
                        <b>{d.attention}</b>
                      </p>
                      <p>{d.tel}</p>
                      <p>{d.address}</p>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Row style={{ paddingBottom: 24 }}>
              <Col offset={6} span={18}>
                {
                  <Card style={{ width: "100%" }}>
                    <p>
                      {notEmptyObject(address) && address.shipping_address?.tel}
                    </p>
                    <p>
                      {notEmptyObject(address) &&
                        address.billing_address?.address}
                    </p>
                  </Card>
                }
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            {" "}
            <Form.Item
              labelCol={{ span: 7 }}
              labelAlign="left"
              label="Shipping Address"
              name="shipping_address"
              rules={[{ required: true }]}
            >
              <Select
                onChange={(v) =>
                  isArrayHasItems(cs.addresses) &&
                  setAddress({
                    ...address,
                    shipping_address: (cs.addresses as Array<any>).find(
                      (i) => i.id === v
                    ),
                  })
                }
                defaultActiveFirstOption={false}
              >
                {isArrayHasItems(cs.addresses) &&
                  (cs.addresses as Array<any>).map((d) => (
                    <Select.Option key={d.id} value={d.id}>
                      <p>
                        <b>{d.attention}</b>
                      </p>
                      <p>{d.tel}</p>
                      <p>{d.address}</p>
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Row>
              <Col offset={7} span={17}>
                {
                  <Card>
                    <p>
                      {notEmptyObject(address) && address.shipping_address?.tel}
                    </p>
                    <p>
                      {notEmptyObject(address) &&
                        address.shipping_address?.address}
                    </p>
                  </Card>
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            {" "}
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 7 }}
              labelAlign="left"
              label="Invoice Date"
              name="invoice_date"
              rules={[{ required: true }]}
              hasFeedback
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            {" "}
            <Form.Item
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 7 }}
              labelAlign="left"
              label="Due Date"
              name="due_date"
              rules={[{ required: true }]}
              hasFeedback
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Salesperson" name="Salesperson">
          <Input></Input>
        </Form.Item>
        <Form.Item label="Discription" name="discription">
          <Input.TextArea></Input.TextArea>
        </Form.Item>
        <Divider dashed />
        <div style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            style={{
              marginBottom: 16,
            }}
            onClick={() => {
              if (items.length > 0) {
                setItems([
                  ...items,
                  { ...defaultItems, sequence: items.length + 1 },
                ]);
              } else {
                setItems([...items, defaultItems]);
              }
            }}
          >
            ADD NEW ITEM
          </Button>
          <Table
            rowKey="id"
            dataSource={items}
            columns={item_column}
            bordered
            pagination={false}
            rowClassName="table-cell-magin-0"
            size="small"
          ></Table>
        </div>
        <Row>
          <Col span={12}>
            {" "}
            <Form.Item
              labelCol={{ span: 6 }}
              labelAlign="left"
              label="Customer Notes"
              name="customer_notes"
            >
              <TextArea rows={8}></TextArea>
            </Form.Item>
          </Col>
          <Col span={12}>
            <table style={{ width: "100%", textAlign: "right" }}>
              <tr>
                <td colSpan={2}>
                  <Form.Item
                    labelCol={{ span: 12 }}
                    label="Sub total"
                    name="sub_total"
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "black",
                      }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td style={{ width: "86%" }}>
                  <Form.Item
                    labelCol={{ span: 14 }}
                    label="Discount:"
                    name="discount"
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      onChange={(v) => {
                        countTotal();
                      }}
                    ></InputNumber>
                  </Form.Item>
                </td>
                <td style={{ float: "right", width: "100%" }}>
                  <Form.Item wrapperCol={{ span: 24 }} name="discount_percent">
                    <Select
                      onChange={(v) => {
                        countTotal();
                      }}
                    >
                      <Select.Option key="1" value={true}>
                        %
                      </Select.Option>
                      <Select.Option key="2" value={false}>
                        $
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Form.Item labelCol={{ span: 12 }} label="Tax:" name="tax">
                    <InputNumber
                      style={{ width: "100%" }}
                      onChange={(v) => {
                        countTotal();
                      }}
                    ></InputNumber>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Form.Item
                    labelCol={{ span: 12 }}
                    label="Total:"
                    name="total"
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        color: "blue",
                        borderColor: "green",
                      }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </td>
              </tr>
            </table>
          </Col>
        </Row>

        <Divider dashed />
        <Form.Item label="Terms Conditions" name="terms_conditions">
          <TextArea rows={5}></TextArea>
        </Form.Item>

        <Form.Item label="Processed" name="processed" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item>
          <div style={{ marginTop: 24 }}>
            {isNew ? (
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 16 }}
              >
                Submit
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 16 }}
              >
                Update
              </Button>
            )}
            <Button type="ghost" onClick={() => netvigator(-1)}>
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}
