import React from "react";
import {  Route, Routes } from "react-router-dom";
import Companies from "./Companies";
import CompanyDetail from "./CompanyDetail";
import NewCompany from "./NewCompany";

const Company = (props: any) => (
  <>
    {/* <Route  exact path={props.match.url + "/"} component={Customer} />
    <Route path={props.match.url + "/detail"} component={CustomerDetail} />
    <Route path={props.match.url + "/new"} component={NewCustomer} />    
    <Route path={props.match.url + "/edit"} component={NewCustomer} />     */}
    {/* <Routes>
      <Route index element={<Customers/>} />
      <Route path="detail" element={<CustomerDetail/>} />
      <Route path="new" element={<NewCustomer/>} />
      <Route path="edit" element={<NewCustomer/>} />
    </Routes> */}
    <Routes>
      <Route index element={<Companies />} />
      <Route path="detail" element={<CompanyDetail />} />
      <Route path="new" element={<NewCompany />} />
      <Route path="edit" element={<NewCompany />} />
    </Routes>
  </>
);

export default Company;