import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  Alert,
  message,
  Modal,
  Dropdown,
  Menu,
  Form,
  Input,
  Upload,
  Image,
  InputNumber,
} from "antd";
import {
  CheckOutlined,
  DownOutlined,
  DeleteOutlined,
  StopOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { getDataForTable } from "../../Utils/getDataForTable";
import "./style.css";
import { useStateContext } from "../../Store/StateProvider";
import { reloadtAction as fileReloadAction } from "../../Reducers/file_reducer";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";


export default function Files(props: any) {
  // table data
  let [data, setData] = useState(null as [{[any:string]:any}]|null);
  let [pageTotal, setPageTotal] = useState();
  let [ids, setIds] = useState([]);
  let [state, dispatch] = useStateContext();
  let netvigate = useNavigate();
  let [uploadModalVisable, setUploadModalVisable] = useState(false);


  let [form] = useForm();


  let [file, setFile] = useState({
    id: 0,
    file_name: "",
    description: "",
    enabled: true,
    type: "Add File",
  });
  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    currentID,
  ] = Object.values(state.file);

  const fetchTableData = useMemo(
    () => () =>
      getDataForTable(
        {
          pageSize,
          pageNo,
          searchField,
          searchContent,
          between,
          conditionType,
          queryType,
          orderBy,
          sortBy,
        },
        setData,
        setPageTotal,
        "/api/files",
        "GET"
      ),
    [
      pageSize,
      pageNo,
      searchField,
      searchContent,
      between,
      conditionType,
      queryType,
      orderBy,
      sortBy,
    ]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  useEffect(() => {
    // load file with id
    if (currentID) {
      axios
        .get("/api/file", {
          params: { id: currentID },
        })
        .then((v) => v.data)
        .then((v) => {
          if (v.success) {
            //setRecord({ ...v.data });
            form.setFieldsValue({
              id: v.data.id,
              description: v.data.description,
              file_name:v.data.file_name
            });
          } else {
            Modal.error({ title: "Server internal error." });
          }
        })
        .catch((e) => {
          Modal.error({ title: "Network error." });
        });
    }
  }, [currentID]);

  function tableChange(pagination: any, filters: any, sorter: any) {
    // handle table sort.----------------

    let orderby: string = "";
    let order: any = "DESC";
    if (sorter.column) {
      orderby = sorter.field;
      order = sorter.order === "descend" ? "DESC" : "ASC";
    } else {
      orderby = "id";
      order = "DESC";
    }

    // end handel table sort--------------^
    dispatch(
      fileReloadAction({
        ...state.file,
        pageNo: pagination.current,
        orderBy: orderby,
        sortBy: order,
        pageSize: pagination.pageSize,
      })
    );
  }

  function updateOrAddRecord(v: any) {
    const formData = new FormData();
    if (state.file.type === "Add File") {
      // add user
      //  v.files = uploadFile;
      //formData.append("file", uploadFile[0].originFileObj);
      formData.append("upload_image", v.image?.fileList[0].originFileObj);
      formData.append("description", v.description);
      axios
        .post("/api/file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              //Modal.info({ title: "Add user success" });
              setUploadModalVisable(false);   
              form.resetFields()
              fetchTableData();           
            } else {
              Modal.error({
                title: "Add user failed.",
                content: JSON.stringify(v.data.msg),
              });
              // setUploadModalVisable(false);
            }
          }
        })
        .catch((e) => {
          Modal.error({ title: "Add user failed.", content: e.message });
        });
    } else {
      // edit file
      if (v.image) {
        formData.append("upload_image", v.image?.fileList[0].originFileObj);
      }
      formData.append("id", v.id);
      formData.append("file_name", v.file_name);
      formData.append("description", v.description);
      //setEditBtnLoading(true);
      axios
        .put("/api/file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((v) => {
          if (v.status === 200) {
            if (v.data.success) {
              //Modal.info({ title: "Add user success" });
              setUploadModalVisable(false);
              fetchTableData();
             // window.location.reload()
            } else {
              Modal.error({
                title: "Edit File failed.",
                content: JSON.stringify(v.data.msg),
              });
            }

          }
        })
        .catch((e) => {
          Modal.error({ title: "Edit File failed." });

        });
    }
  }

  function deleteRecord(id: any, bulk: boolean = false) {
    axios
      .delete(bulk ? "/api/file_bulk_delete" : "/api/file", {
        data: { id: id },
      })
      .then((v) => {
        console.log(v);
        if (v.status === 200) {
          if (v.data.success) {
            fetchTableData();

            setIds([]);
            message.success("Delete success.");
          } else {
            Modal.error({
              title: "Error",
              content: JSON.stringify(v.data.msg),
            });
          }
        } else {
          Modal.error({ title: "Server error" });
        }
      })
      .catch((e) => {
        Modal.error({ title: "network error" });
      });
  }

  function disEnFile(id: any, enabled: boolean, isBulk: boolean = false) {
    axios
      .put(isBulk ? "/api/file_bulk_disable" : "/api/file", {
        id: id,
        enabled: enabled,
      })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            fetchTableData();
            setIds([]);
            message.success("Action success.");
          } else {
            Modal.error({
              title: "error.",
              content: JSON.stringify(v.data.msg),
            });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  const action_menu = (
    <Menu>
      {ids.length === 1 && (
        <Menu.Item
          key="edit"
          onClick={() => {
            if (ids.length !== 1) {
              Modal.info({
                title: "Error",
                content: "Select one file first.",
              });
            } else {
              dispatch(
                fileReloadAction({
                  ...state.file,
                  currentID: ids[0],
                  type: "Edit File",
                })
              );
              setUploadModalVisable(true);
            }
          }}
        >
          <EditOutlined />
          Edit
        </Menu.Item>
      )}
      <Menu.Item
        key="delete"
        onClick={() => {
          Modal.confirm({
            title: "Are you confirm to delete?",
            onOk() {
              deleteRecord(ids, true);
            },
          });
        }}
      >
        <DeleteOutlined />
        Delete
      </Menu.Item>
      <Menu.Item key="disable" onClick={() => disEnFile(ids, false, true)}>
        <StopOutlined />
        Disable
      </Menu.Item>
      <Menu.Item key="enable" onClick={() => disEnFile(ids, true, true)}>
        <CheckOutlined />
        Enable
      </Menu.Item>
    </Menu>
  );

  let columns1 = [
    {
      title: "File Name",
      dataIndex: ["id", "file_name"],
      sorter: true,
      width: "30%",
      sortOrder:
        orderBy === "file_name"
          ? sortBy === "ASC"
            ? "ascend"
            : ("descend" as "descend" | "ascend" | null | undefined)
          : null,
      render: (text: any, record: any, index: any) => {
        return (
          <Image
            style={{ maxWidth: "80px" }}
            src={`/api/get_image?id=${record.id}#${new Date().getTime()}`}
          />
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "40%",
      sortOrder:
        orderBy === "description"
          ? sortBy === "ASC"
            ? "ascend"
            : ("descend" as "descend" | "ascend" | null | undefined)
          : null,
      sorter: true,
      ellipsis: true,
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      // key:"enabled",
      width: "30%",
      sorter: true,
      sortOrder:
        orderBy === "enabled"
          ? sortBy === "ASC"
            ? "ascend"
            : ("descend" as "descend" | "ascend" | null | undefined)
          : null,
      render: (text: any, record: any, index: any) => {
        if (record.enabled) {
          return <CheckOutlined style={{ color: "green" }} />;
        }
      },
    },
  ];

  return (
    <>
      <Modal
        title={state.file.currentID ? "Edit file" : "Upload file"}
        visible={uploadModalVisable}
        onCancel={() => {
          setUploadModalVisable(false);
          dispatch(fileReloadAction({ ...state.file, currentID: null }));
          form.resetFields()
        }}
        footer={false}
        destroyOnClose
      >
        <div>
          <Form
            name="File"
            labelCol={{ span: 5, style: { textAlign: "left" } }}
            wrapperCol={{ span: 19 }}
            onFinish={updateOrAddRecord}
            form={form}
          >
            <Form.Item label="id" name="id" style={{ display: "none" }}>
              <InputNumber></InputNumber>
            </Form.Item>
            <Form.Item label="file_name" name="file_name" style={{ display: "none" }}>
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Image"
              name="image"
              rules={[{ required: state.file.currentID ? false : true }]}
              hasFeedback
            >
              <Upload
                listType="picture-card"
                maxCount={1}
                beforeUpload={(file, fileList) => {
                  if (file.type.indexOf("image/") >= 0) {
                    return false;
                  } else {
                    return Upload.LIST_IGNORE;
                  }
                }}
                data={{ testPram: "testPram" }}
                accept="image/*"
              >
                +
              </Upload>
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, min: 5 }]}
              hasFeedback
            >
              <Input></Input>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 16 }}
              >
                {state.file.type === "Add File" ? "Add" : "Edit"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <div>
        <Row>
          <Col span={2}>
            <div
              style={{ marginBottom: 16, verticalAlign: "middle", height: 40 }}
            >
              <Button
                style={{ marginRight: 8, height: 40 }}
                type="primary"
                onClick={() => {
                  setUploadModalVisable(true);
                  dispatch(
                    fileReloadAction({ ...state.file, type: "Add File" })
                  );
                }}
              >
                NEW
              </Button>{" "}
            </div>
          </Col>
          <Col span={6}>
            {ids.length > 0 && (
              <div
                style={{
                  display: "table",
                  width: "100%",
                }}
              >
                <div style={{ display: "table-cell" }}>
                  <Alert
                    message={`Select ${ids.length} Item(s). `}
                    type="success"
                    closable
                    onClose={() => {
                      setIds([]);
                    }}
                  />
                </div>
                <div style={{ display: "table-cell", width: "20%" }}>
                  <Dropdown overlay={action_menu}>
                    <Button style={{ height: 40 }}>
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            )}
          </Col>
          <Col span={16}>
            {searchField && (
              <Alert
                message="Search Criteria: Name, Primary Contact, Tel, Fax, Email...  Close this message to cancel search mode."
                type="success"
                closable
                afterClose={() =>
                  dispatch(
                    fileReloadAction({
                      ...state.file,
                      searchField: "",
                      searchContent: "",
                    })
                  )
                }
              />
            )}
          </Col>
        </Row>

        <Table
          rowKey="id"
          tableLayout="fixed"
          onRow={(record) => {
            return {
              onClick: (event) => {
                //if ((event.target as any).cellIndex !== 0&&(event.target as any).type !== 'checkbox'&&!((event.target as any).className.indexOf('ant-checkbox')>=0)) {
                // skip checkbox click event.
                if (
                  [2,3].includes((event.target as any).cellIndex) &&
                  !(
                    (event.target as any).className.indexOf("ant-checkbox") >= 0
                  )
                ) {
                  dispatch(
                    fileReloadAction({
                      ...state.file,
                      type: "Edit File",
                      currentID: record.id,
                    })
                  );
                  setUploadModalVisable(true);
                }
              },
              onMouseEnter: (event) => {
                event.currentTarget.setAttribute("style", "cursor: pointer;");
              },
            };
          }}
          bordered
          columns={columns1}
          dataSource={data?data:[]}
          loading={data?false:true}
          pagination={{
            current: pageNo,
            total: pageTotal,
            pageSize: pageSize,
            defaultPageSize: 8,
            showSizeChanger: true,
            pageSizeOptions: ["8", "16", "24", "30", "40", "50", "100", "200"],
            // onChange:(pageNo,pageSize)=>{dispatch(fileReloadAction({
            //   ...state.file,
            //   pageNo:pageNo
            // }))}
          }}
          onChange={tableChange}
          style={{ minHeight: 500 }}
          rowSelection={{
            selections: true,
            selectedRowKeys: ids,
            onChange: (selectKeys) => {
              setIds(selectKeys as []);
            },
          }}
        ></Table>
      </div>
    </>
  );
}
