export const testReducer = (state: any, action: any) => {


    switch (action.type) {
        case 'ADD1':
            return { ...state, test1: action.playload }
        case 'ADD2':
            return { ...state, test2: action.playload }
        default:
            return state

    }
}