import { checkLoginReducer } from './login_reducer';
import { testReducer } from './test_reducter';
import { reducer as productReducer } from './product_reducer';
import { reducer as customerReducer } from './customer_reducer';
import { reducer as companyReducer } from './company_reducer';
import { reducer as invoiceReducer } from './invoice_reducer';
import { reducer as quotationReducer } from './quotation_reducer';
import { reducer as dnoteReducer } from './dnote_reducer';
import { reducer as fileReducer } from './file_reducer';
import { defaultStateType } from '../Store/defaultStatus';



export const mainReducer = (state: defaultStateType, action: any) => {
    let states={
        login_state: checkLoginReducer(state.login_state, action),
        product:productReducer(state.product,action),
        customer:customerReducer(state.customer,action),
        company:companyReducer(state.company,action),
        invoice:invoiceReducer(state.invoice,action),
        quotation:quotationReducer(state.quotation,action),
        dnote:dnoteReducer(state.dnote,action),
        file:fileReducer(state.file,action),
        test: testReducer(state.test, action)
    }
//    console.log(state)
    localStorage.setItem('MyManagerStates',JSON.stringify(states))

    return (states)
}