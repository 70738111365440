import React from "react";
//import logo from './logo.svg';
//import './App.css';
// import { render } from '@testing-library/react';
import { mainReducer } from "./Reducers/main_reducer";
import { defaultState } from "./Store/defaultStatus";
import { StateProvider } from "./Store/StateProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import Main from "./Layout/Main";
import Dashboard from "./Layout/Dashboard/Dashboard";
import Invoice from "./Layout/Invoice/Invoice";
import Quotation from "./Layout/Quotation/Quotation";
import Customer from "./Layout/Customer/Customer";
import Product from "./Layout/Product/Product";
import User from "./Layout/User/User";
import Login from "./Layout/Login";
import Loading from "./Layout/Loading";
import ErrorPage from "./Layout/Error";
import Company from "./Layout/Company/Company";
import File from "./Layout/File/File";
import InvoicePrint from "./Layout/Invoice/InvoicePrint";
import Dnote from "./Layout/Dnote/Dnote";
import QuotationPrint from "./Layout/Quotation/QuotationPrint";
import DnotePrint from "./Layout/Dnote/DnotePrint";

const App = () => {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>

    // </div>
    <StateProvider reducer={mainReducer} initialState={defaultState}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />
            <Route path="main" element={<Main />}>
              <Route index element={<Dashboard />}></Route>
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route path="invoice/*" element={<Invoice />}></Route>
              <Route path="quotation/*" element={<Quotation />}></Route>
              <Route path="dnote/*" element={<Dnote />}></Route>
              <Route path="customer/*" element={<Customer />}></Route>
              <Route path="product/*" element={<Product />}></Route>
              <Route path="company/*" element={<Company />}></Route>
              <Route path="file/*" element={<File />}></Route>
              <Route path="user" element={<User />}></Route>
            </Route>
            <Route path="print/invoice" element={<InvoicePrint />} />
            <Route path="print/quotation" element={<QuotationPrint />} />
            <Route path="print/dnote" element={<DnotePrint />} />
            <Route path="login" element={<Login />} />
            <Route path="error" element={<ErrorPage />} />
            <Route path="loading" element={<Loading />} />
            <Route path="*" element={<Layout />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StateProvider>
  );
};

export default App;
