import React from "react";
import { Route, Routes } from "react-router-dom";
import Dnotes from "./Dnotes";
import DnoteDetail from "./DnoteDetail";
import NewDnote from "./NewDnote";
import DnotePrint from "./DnotePrint";


export default function Dn(props: any) {
  console.log("dnote");
  return (
    <>
      <Routes>
        <Route index element={<Dnotes />} />
        <Route path="detail" element={<DnoteDetail />} />
        <Route path="new" element={<NewDnote />} />
        <Route path="edit" element={<NewDnote />} />
        <Route path="print" element={<DnotePrint />} />
      </Routes>
    </>
  );
}
