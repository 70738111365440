import React from "react";
import { Button, Modal, Tooltip } from "antd";
import axios from "axios";
import { useStateContext } from "../Store/StateProvider";
import { setLoginStateAction } from "../Reducers/login_reducer";
import { LogoutOutlined } from "@ant-design/icons";


const Logout= () => {
  let [, dispatch] = useStateContext();
  const doLogout = () => {
    axios.get("/api/logout").then((v) => {
      if (v.status === 200) {
        if (v.data.success) {            
          dispatch(setLoginStateAction({ state: "false" }));
          localStorage.clear()
        } else {
          Modal.error({
            title: "Error",
            content: "Logout error..",
          });
        }
      } else {
        Modal.error({
          title: "Error",
          content: "Network error..",
        });
      }
    });
  };
  const confirmLogut = () => {
    Modal.confirm({
      title: "Are you sure to logout?",
      onOk: doLogout,
    });
  };
  return (
    <>
      <Tooltip placement="bottom" title="Logout">
        <Button
          onClick={confirmLogut}
          type="primary"
          danger
          icon={<LogoutOutlined />}
        ></Button>
      </Tooltip>
    </>
  );
};

export default Logout
