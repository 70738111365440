import React from "react";
import { Route, Routes } from "react-router-dom";
import Invoices from "./Invoices";
import InvoiceDetail from "./InvoiceDetail";
import NewInvoice from "./NewInvoice";
import InvoicePrint from "./InvoicePrint";

export default function Invoice(props: any) {
  console.log("invoice");
  return (
    <>
      <Routes>
        <Route index element={<Invoices />} />
        <Route path="detail" element={<InvoiceDetail />} />
        <Route path="new" element={<NewInvoice />} />
        <Route path="edit" element={<NewInvoice />} />
        <Route path="print" element={<InvoicePrint />} />
      </Routes>
    </>
  );
}
