import React from "react";
import { Route, Routes } from "react-router-dom";
import Quotations from "./Quotations";
import QuotationDetail from "./QuotationDetail";
import NewQuotation from "./NewQuotation";
import QuotationPrint from "./QuotationPrint";

export default function Quotation(props: any) {
  console.log("quotation");
  return (
    <>
      <Routes>
        <Route index element={<Quotations />} />
        <Route path="detail" element={<QuotationDetail />} />
        <Route path="new" element={<NewQuotation />} />
        <Route path="edit" element={<NewQuotation />} />
        <Route path="print" element={<QuotationPrint />} />
      </Routes>
    </>
  );
}
