import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import {
  Table,

  Row,
  Col,

} from "antd";
import {
  CheckOutlined,

} from "@ant-design/icons";
import { getDataForTable } from "../../Utils/getDataForTable";
import "./style.css";
import { useStateContext } from "../../Store/StateProvider";
import { reloadtAction as invoiceReloadAction } from "../../Reducers/invoice_reducer";
import { genDatetimeFormat } from "../../Utils/genDateTimeFormat";
import { useNavigate } from "react-router-dom";

export default function Dashboard(props: any) {
  // table data
  let [data, setData] = useState(null as [{[any:string]:any}]|null);
  let [pageTotal, setPageTotal] = useState();

  let [state, dispatch] = useStateContext();
  let netvigate = useNavigate();


  let [param, setParam] = useState({
    pageSize: 8,
    searchField: "processed",
    searchContent: 0,
    pageNo: 1,
    queryType: "=",
    orderBy: "id",
    sortBy: "DESC",
    conditionType: "or",
  });

  const fetchTableData = useMemo(
    () => () =>
      getDataForTable(
        {
          ...param,
        },
        setData,
        setPageTotal,
        "/api/invoices",
        "GET"
      ),
    [param]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  function tableChange(pagination: any, filters: any, sorter: any) {
    // handle table sort.----------------

    let orderby: string = "";
    let order: any = "DESC";
    if (sorter.column) {
      orderby = sorter.field;
      order = sorter.order === "descend" ? "DESC" : "ASC";
    } else {
      orderby = "id";
      order = "DESC";
    }

    // end handel table sort--------------^
    setParam({
      ...param,
      pageNo: pagination.current,
      orderBy: orderby,
      sortBy: order,
      pageSize: pagination.pageSize,
    });
    // dispatch(
    //   invoiceReloadAction({
    //     ...state.product,
    //     pageNo: pagination.current,
    //     orderBy: orderby,
    //     sortBy: order,
    //     pageSize: pagination.pageSize,
    //   })
    // );
  }





  let columns1 = [
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      //   sorter: true,
      //  key:'type'  ,
      render: (_: any, record: any) => {
        return genDatetimeFormat("-d", record.invoice_date);
      },
      //   sortOrder:
      //     orderBy === "invoice_date"
      //       ? sortBy === "ASC"
      //         ? "ascend"
      //         : ("descend" as "descend" | "ascend" | null | undefined)
      //       : null,
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_no",
      width: "20%",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      width: "40%",
      // key:"description",
      ellipsis: true,
      render: (_: any, record: any) => {
        return record.customer.name;
      },
    },
    {
      title: "Processed",
      dataIndex: "processed",
      render: (text: any, record: any, index: any) => {
        if (record.processed) {
          return <CheckOutlined style={{ color: "green" }} />;
        }
      },
    },
  ];

  return (
    <>
      <div>
        <Row>
          <Col span={8}>
            <div
              style={{
                marginBottom: 16,
                verticalAlign: "middle",
                height: 40,
                fontWeight: "bold",
                marginLeft: 10,
              }}
            >
              Invoice Has not Processed
            </div>
          </Col>

          <Col span={16}></Col>
        </Row>

        <Table
          rowKey="id"
          tableLayout="fixed"
          onRow={(record) => {
            return {
              onClick: (event) => {
                //if ((event.target as any).cellIndex !== 0&&(event.target as any).type !== 'checkbox'&&!((event.target as any).className.indexOf('ant-checkbox')>=0)) {
                // skip checkbox click event.
                if (
                  (event.target as any).cellIndex !== 0 &&
                  !(
                    (event.target as any).className.indexOf("ant-checkbox") >= 0
                  )
                ) {
                  dispatch(
                    invoiceReloadAction({
                      ...state.invoice,
                      currentID: record.id,
                    })
                  );
                  netvigate("../invoice/detail");
                }
              },
              onMouseEnter: (event) => {
                event.currentTarget.setAttribute("style", "cursor: pointer;");
              },
            };
          }}
          bordered
          columns={columns1}
          dataSource={data?data:[]}
          loading={data?false:true}
          pagination={{
            current: param.pageNo,
            total: pageTotal,
            pageSize: param.pageSize,
            defaultPageSize: 8,
            showSizeChanger: true,
            pageSizeOptions: ["8", "16", "24", "30", "40", "50", "100", "200"],
            onShowSizeChange: (current, pageSize) =>
              setParam({ ...param, pageSize: pageSize, pageNo: 1 }),
          }}
          onChange={tableChange}
          style={{ minHeight: 500 }}
          //   rowSelection={{
          //     selections: true,
          //     selectedRowKeys: ids,
          //     onChange: (selectKeys) => {
          //       setIds(selectKeys as []);
          //     },
          //   }}
        ></Table>
      </div>
    </>
  );
}
