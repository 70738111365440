export default function textToHtmlBR(text: string) {
  return text?.split("\n").map((i: any) => {
    return (
      <>
        {i}
        <br />
      </>
    );
  });
}
