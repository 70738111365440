import React from "react";
import { Route, Routes } from "react-router-dom";
import NewProduct from "./NewProduct";
import Products from "./Products";
import ProductsDetail from "./ProductsDetail";

export default function Product(props: any) {
  console.log("Product");
  return (
    <>
      {/* <Route  exact path={props.match.url + "/"} component={Products} />
    <Route path={props.match.url + "/detail/"} component={ProductsDetail} />
    <Route path={props.match.url + "/new"} component={NewProduct} />    
    <Route path={props.match.url + "/edit"} component={NewProduct} />     */}

      <Routes>
        <Route index element={<Products/>} />
        <Route path="/detail/" element={<ProductsDetail/>} />
        <Route path="/new" element={<NewProduct/>} />
        <Route path="/edit" element={<NewProduct/>} />
      </Routes>
    </>
  );
}
