import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Tabs,
  Tooltip,
  Popconfirm,
  Alert,
  Dropdown,
  Menu,
  message,
  Modal,
  Descriptions,
  Divider,
} from "antd";
import {
  CheckOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  CopyOutlined,
  PauseOutlined,
  PlusOutlined,
  CaretRightOutlined,
  DownOutlined,
  StopOutlined,
  PrinterOutlined,
  FileSearchOutlined
} from "@ant-design/icons";
import { getDataForTable } from "../../Utils/getDataForTable";
import "./style.css";
import { useStateContext } from "../../Store/StateProvider";
import { reloadtAction } from "../../Reducers/dnote_reducer";
import { genDatetimeFormat } from "../../Utils/genDateTimeFormat";
import { useNavigate } from "react-router-dom";
import DnotePrint from "./DnotePrint";
import textToHtmlBR from "../../Utils/textToHtmlBR";

export default function DnDetail(props: any) {
  let hkd = Intl.NumberFormat("en-HK");
  let [data, setData] = useState([]);
  let [pageTotal, setPageTotal] = useState();
  let [ids, setIds] = useState([]);

  let [state, dispatch] = useStateContext();
  let netvigate = useNavigate();
  let [showPrintModal,setShowPrintModal] =useState(false)

  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    currentID,
  ] = Object.values(state.dnote);

  let defaultRecord: { [pro: string]: any } = {};

  let [record, setRecord] = useState(defaultRecord);

  const fetchTableData = useMemo(
    () => () =>
      getDataForTable(
        {
          pageSize,
          pageNo,
          searchField,
          searchContent,
          between,
          conditionType,
          queryType,
          orderBy,
          sortBy,
        },
        setData,
        setPageTotal,
        "/api/dnotes",
        "GET"
      ),
    [
      pageSize,
      pageNo,
      searchField,
      searchContent,
      between,
      conditionType,
      queryType,
      orderBy,
      sortBy,
    ]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  // call details.
  useEffect(() => {
    axios
      .get("/api/dnote", {
        params: { id: currentID },
      })
      .then((v) => v.data)
      .then((v) => {
        if (v.success) {
          setRecord({ ...v.data });
        } else {
          Modal.error({ title: "Server internal error." });
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }, [currentID]);

  // Delete  record.
  function deleteRecord(id: any, bulk: boolean = false) {
    axios
      .delete(bulk ? "/api/dnote_bulk_delete" : "/api/dnote", {
        data: { id: id },
      })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            fetchTableData();
            setRecord({
              id: null,
              type: "",
              name: "",
              primary_contact: "",
              email: "",
              tel: "",
              fax: "",
              website: "",
              remark: "",
              processed: null,
              contacts: [],
              addresses: [],
            });
            setIds([]);
            netvigate("../");
            message.success("Delete success.");
          } else {
            Modal.error({ title: "Error", content: v.data.msg });
          }
        } else {
          Modal.error({ title: "Server error" });
        }
      })
      .catch((e) => {
        Modal.error({ title: "network error" });
      });
  }

  function disableItem(id: any, processed: boolean, isBulk: boolean = false) {
    axios
      .put(isBulk ? "/api/dnote_bulk_disable" : "/api/dnote", {
        id: id,
        processed: processed,
      })
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            setRecord({ ...record, processed: processed });
            fetchTableData();
            setIds([]);
            message.success("Action success.");
          } else {
            Modal.error({
              title: "error.",
              content: JSON.stringify(v.data.msg),
            });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  function tableChange(pagination: any, filters: any, sorter: any) {
    // handle table sort.----------------

    let orderby: string = "";
    let order: any = "DESC";
    if (sorter.column) {
      orderby = sorter.field;
      order = sorter.order === "descend" ? "DESC" : "ASC";
    } else {
      orderby = "id";
      order = "DESC";
    }

    // end handel table sort--------------^
    dispatch(
      reloadtAction({
        ...state.dnote,
        pageNo: pagination.current,
        orderBy: orderby,
        sortBy: order,
        pageSize: pagination.pageSize,
      })
    );
  }

  const action_menu = (
    <Menu>
      <Menu.Item
        key="delete"
        onClick={() => {
          Modal.confirm({
            title: "Are you confirm to delete?",
            onOk() {
              deleteRecord(ids, true);
            },
          });
        }}
      >
        <DeleteOutlined />
        Delete
      </Menu.Item>
      <Menu.Item key="disable" onClick={() => disableItem(ids, false, true)}>
        <PauseOutlined />
        Disable
      </Menu.Item>
      <Menu.Item key="enable" onClick={() => disableItem(ids, true, true)}>
        <CaretRightOutlined />
        Enable
      </Menu.Item>
    </Menu>
  );

  let items_column = [
    {
      title: "Item Details",
      dataIndex: "item_details",
      render: (text: any, record: any, index: any) => {
        return record.item_details?.split("\n").map((i: any) => {
          return <>{i}<br/></>;
        })

      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (text: any, record: any, index: any) => {
        return hkd.format(record.price);
      },
    },
    {
      title: "Discount",
      dataIndex: ["discount", "discount_percent"],      
      render: (text: any, record: any, index: any) => {
        return record.discount_percent
          ? record.discount + "%"
          :record.discount;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text: any, record: any, index: any) => {
        return hkd.format(record.amount);
      },
    },
  ];

  let columns = [
    {
      title: "DNote",
      dataIndex: "dnote_no",
      sortOrder:
        orderBy === "dnote_no"
          ? sortBy === "ASC"
            ? "ascend"
            : ("descend" as "descend" | "ascend" | null | undefined)
          : null,
      sorter: true,
      ellipsis: true,
      render: (text: any, record: any, index: any) => {
        return <span style={{ color: "#1890ff" }}>{text}</span>;
      },
    },
  ];

  return (
    <>
      <Modal visible={showPrintModal} width={'240mm'} style={{ top: 20 }} onCancel={() =>{setShowPrintModal(false)}} destroyOnClose footer={null}>
        <DnotePrint auto_print={false}></DnotePrint>
      </Modal>
      <div style={{ display: "table", width: "100%" }}>
        <div style={{ width: 300, display: "table-cell" }}>
          {searchField && (
            <Alert
              message="Search Criteria: Name, Description.  Close this message to cancel search mode."
              type="success"
              closable
              afterClose={() =>
                dispatch(
                  reloadtAction({
                    ...state.dnote,
                    searchField: "",
                    searchContent: "",
                  })
                )
              }
            />
          )}

          {ids.length > 0 && (
            <div
              style={{
                display: "table",
                width: "100%",
              }}
            >
              <div style={{ display: "table-cell" }}>
                <Alert
                  message={`Select ${ids.length} Item(s). `}
                  type="success"
                  closable
                  onClose={() => {
                    setIds([]);
                  }}
                />
              </div>
              <div style={{ display: "table-cell", width: "20%" }}>
                <Dropdown overlay={action_menu}>
                  <Button style={{ height: 40 }}>
                    Actions <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          )}
          <Table
            rowKey="id"
            tableLayout="fixed"
            onRow={(record) => {
              return {
                onClick: (event) => {
                  dispatch(
                    reloadtAction({
                      ...state.dnote,
                      currentID: record.id,
                    })
                  );
                  //setRecord({ ...record });
                },
                onMouseEnter: (event) => {
                  event.currentTarget.setAttribute("style", "cursor: pointer;");
                },
              };
            }}
            bordered
            columns={columns}
            dataSource={data}
            pagination={{
              current: pageNo,
              total: pageTotal,
              pageSize: pageSize,
              size: "small",
              defaultPageSize: 8,
              showSizeChanger: true,
              pageSizeOptions: [
                "8",
                "16",
                "24",
                "30",
                "40",
                "50",
                "100",
                "200",
              ],
              onShowSizeChange: (current, pageSize) =>
                dispatch(
                  reloadtAction({
                    ...state.dnote,
                    pageSize: pageSize,
                    pageNo: 1,
                  })
                ),
            }}
            onChange={tableChange}
            style={{ minHeight: 500, width: 300 }}
            rowClassName={(record, index) => {
              if (record.id === currentID) {
                return "my-table-row-selected";
              } else {
                return "";
              }
            }}
            rowSelection={{
              selections: true,
              selectedRowKeys: ids,
              onChange: (selectKeys) => {
                setIds(selectKeys as []);
              },
            }}
            scroll={pageSize <= 8 ? undefined : { y: 500 }}
          ></Table>
        </div>
        <div style={{ display: "table-cell" }}>
          <div>
            <div style={{ height: 56, display: "table", width: "100%" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  paddingLeft: 16,
                  width: "50%",
                  fontWeight: "bold",
                }}
              >
                {record.dnote_no}
              </div>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "right",
                }}
              >
                {" "}

                <Tooltip placement="bottom" title={"Preview"}>
                  <Button
                    icon={<FileSearchOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={()=>setShowPrintModal(true)}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Print"}>
                  <Button
                    icon={<PrinterOutlined />}
                    style={{ marginRight: 16 }}
                    // onClick={()=>setShowPrintModal(true)}
                    onClick={()=>window.open('/?redirect=/print/dnote','_blank')}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Add new item"}>
                  <Button
                    icon={<PlusOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={() => netvigate("../new")}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Edit this item"}>
                  <Button
                    icon={<EditOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      if (currentID === null) {
                        Modal.info({
                          title: "Error",
                          content: "Select one Dnote first.",
                        });
                      } else {
                        netvigate("../edit");
                      }
                    }}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Copy this item"}>
                  <Button
                    icon={<CopyOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={() => netvigate("../new?copy")}
                  ></Button>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={
                    record.processed ? "Not processed" : "Processed"
                  }
                >
                  <Button
                    icon={record.processed ? <StopOutlined /> : <CheckOutlined />}
                    style={{ marginRight: 16 }}
                    onClick={() => disableItem(record.id, !record.processed)}
                  ></Button>
                </Tooltip>
                <Tooltip placement="bottom" title={"Delete this item"}>
                  <Popconfirm
                    placement="top"
                    title="Are sure to delete this item?"
                    onConfirm={() => deleteRecord(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      style={{ marginRight: 16 }}
                    ></Button>
                  </Popconfirm>
                </Tooltip>
                <Tooltip placement="bottom" title={"Close"}>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => netvigate("../")}
                  ></Button>
                </Tooltip>
              </div>
            </div>
            <div>
              <Tabs type="card">
                <Tabs.TabPane tab="Overview" key="1">
                  <Descriptions
                    style={{ paddingLeft: 28 }}
                    layout="horizontal"
                    bordered
                    column={3}
                  >
                    <Descriptions.Item label="DNote No.:">
                      {record.dn_no}
                    </Descriptions.Item>
                    <Descriptions.Item label="DNote Date:">
                      {genDatetimeFormat("-d", record.dnote_date)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Due Date:">
                      {genDatetimeFormat("-d", record.Due_date)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Company" span={3}>
                      {record.company?.company_name_en}
                      <br />
                      {record.company?.company_name_ch}
                    </Descriptions.Item>
                    <Descriptions.Item label="Customer" span={3}>
                      {record.customer?.name}
                    </Descriptions.Item>

                    <Descriptions.Item label="Billing Address" span={3}>
                      {record.billing_address?.address}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact">
                      {record.billing_address?.attention}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tel" span={2}>
                      {record.billing_address?.tel}
                    </Descriptions.Item>

                    <Descriptions.Item label="Salesperson" span={3}>
                      {record.Salesperson}
                    </Descriptions.Item>

                    <Descriptions.Item label="Discription" span={3}>
                      {record.discription}
                    </Descriptions.Item>

                    <Descriptions.Item label="Processed" span={1}>
                      {record.processed ? (
                        <CheckOutlined style={{ color: "green" }} />
                      ) : (
                        <CloseOutlined style={{ color: "red" }} />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Created" span={1}>
                      {record.created_date &&
                        genDatetimeFormat("-", record.created_date)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Updated" span={1}>
                      {record.updated_date &&
                        genDatetimeFormat("-", record.updated_date)}
                    </Descriptions.Item>

                    <Descriptions.Item label="Items" span={3}>
                      <Table
                        key="id"
                        size="small"
                        bordered
                        columns={items_column}
                        pagination={false}
                        dataSource={record.items}
                        summary={() => {
                          return (
                            <tr>
                              <td></td>
                              <td>Discount:</td>
                              <td>
                                {record.discount_percent
                                  ?record.discount + "%"
                                  :"HK$ "+ hkd.format(record.discount)}
                              </td>
                              <td>Total:</td>
                              <td> {"HK$ "+ hkd.format(record.total)}</td>
                            </tr>
                          );
                        }}
                      ></Table>
                    </Descriptions.Item>
                    <Descriptions.Item label="Terms conditions" span={3}>
                      {textToHtmlBR(record.terms_conditions)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Ref No." span={3}>
                      {record.ref_no}
                    </Descriptions.Item>                    
                  </Descriptions>
                  ,
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
