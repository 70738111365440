// variable
// record, setRecord for store Quotation info
// defaultRecord is record type
// state for gobal state object

import { Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import "../../css/print.css";
import { useStateContext } from "../../Store/StateProvider";
import { genDatetimeFormat } from "../../Utils/genDateTimeFormat";
import { getDataForTable } from "../../Utils/getDataForTable";
import textToHtmlBR from "../../Utils/textToHtmlBR";

export default function QuotationPrint({ auto_print = true }) {
  console.log("print page.");
  let [state, dispatch] = useStateContext();

  let defaultRecord: { [pro: string]: any } = {};
  let [record, setRecord] = useState(defaultRecord);
  auto_print&& (document.title = "QUO" + record.quotation_no); // auto print set title for save file name.

  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
    currentID,
  ] = Object.values(state.quotation);

  useEffect(() => {
    axios
      .get("/api/quotation", {
        params: { id: currentID },
      })
      .then((v) => v.data)
      .then((v) => {
        if (v.success) {
          setRecord({ ...v.data });
        } else {
          Modal.error({ title: "Server internal error." });
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }, []);

  useEffect(() => {
    if (auto_print) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [auto_print]);

  // function tenRowTableforItems(record: [any]) {
  //   if (record?.length < 10) {
  //     let array = [];
  //     for (let i = 0; i <= 9; i++) {
  //       array.push(
  //         <tr>
  //           <td>{record[i]?.sequence}</td>
  //           <td>{record[i]?.item_details}</td>
  //           <td> {record[i]?.quantity}</td>
  //           <td>
  //             {isNaN(record[i]?.price) ? (
  //               <>&nbsp;</>
  //             ) : (
  //               Intl.NumberFormat("en-US").format(record[i]?.price)
  //             )}
  //           </td>
  //           <td>{record[i]?.discount}</td>
  //           <td>
  //             {isNaN(record[i]?.price) ? (
  //               <>&nbsp;</>
  //             ) : (
  //               Intl.NumberFormat("en-US").format(record[i]?.amount)
  //             )}
  //           </td>
  //         </tr>
  //       );
  //     }
  //     return array;
  //   } else {
  //     record?.map((i: any) => {
  //       return (
  //         <tr>
  //           <td>{i.sequence}</td>
  //           <td>{i.item_details}</td>
  //           <td> {i.quantity}</td>
  //           <td>{Intl.NumberFormat("en-US").format(i.price)}</td>
  //           <td>{i.discount}</td>
  //           <td>{Intl.NumberFormat("en-US").format(i.amount)}</td>
  //         </tr>
  //       );
  //     });
  //   }
  // }

  return (
    <>
      <div className="div_body">
        <div className="main-page">
          <div className="sub-page">
            <div className="table_w100">
              <div>
                <div className="company_hearder">
                  <div>
                    <div>{record.company?.company_name_en}</div>
                    <div>QUOTATION</div>
                  </div>
                </div>
              </div>
              <div className="address_and_invoiceno_row">
                <div className="address_and_invoiceno_table">
                  <div>
                    <div>
                      <div className="address_table">
                        <div>
                          Tel: {record.company?.tel} Fax: {record.company?.fax}
                        </div>
                        <div>
                          {record.company?.address_en}
                          <br />
                          {record.company?.address_ch}
                        </div>
                        <div>
                          Contact:{record.company?.contact} Email:
                          {record.company?.email}
                        </div>
                      </div>
                    </div>
                    <div className="invoiceno_cell">
                      <div className="invoiceno_table">
                        <div>
                          <div>Quotation No.:</div>
                          <div>
                            <span>{record.quotation_no}</span>
                          </div>
                        </div>
                        <div>
                          <div>Date:</div>
                          <div>
                            <span>
                              {record.quotation_date &&
                                genDatetimeFormat("m", record.quotation_date)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="attn_row">
                <div className="attn_table">
                  <div>
                    <div>
                      <div className="attn_sub_table">
                        <div>
                          <div className="attn_sub_table_header">Customer:</div>
                        </div>
                        <div>
                          <div className="attn_sub_table_contect">
                            <b> {record.customer?.name}</b>
                            <br />
                            <span>
                              {" "}
                              {record.billing_address?.attention}
                              <br />
                              {record.billing_address?.tel}
                              <br />
                              {record.billing_address?.address}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="attn_sub_table_middle_cell"></span>
                    </div>
                    <div>
                      <div className="attn_sub_table">
                        <div>
                          <div className="attn_sub_table_header">
                            Description:
                          </div>
                        </div>
                        <div>
                          <div className="attn_sub_table_contect">{record.discription}</div>                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="items_row">
                <table className="items_table">
                  <thead>
                    <tr>
                      <td>Item</td>
                      <td>Description</td>
                      <td>Qty</td>
                      <td>Unit Price</td>
                      <td>Discount</td>
                      <td>Line Total</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      //tenRowTableforItems(record.items)

                      record.items?.map((i: any) => {
                        return (
                          <tr>
                            <td>{i.sequence}</td>
                            <td>
                              {i.item_details?.split("\n").map((i: any) => {
                                return (
                                  <>
                                    {i}
                                    <br />
                                  </>
                                );
                              })}
                            </td>
                            <td> {i.quantity}</td>
                            <td style={{textAlign:'right'}}>
                              {Intl.NumberFormat("en-US").format(i.price)}
                            </td>
                            <td>{i.discount}</td>
                            <td style={{textAlign:'right'}}>
                              {Intl.NumberFormat("en-US").format(i.amount)}
                            </td>
                          </tr>
                        );
                      })
                    }

                    <tr>
                      <td colSpan={2}>Special Notes and Instructions</td>

                      <td colSpan={2} rowSpan={2}>
                        Subtotal
                      </td>

                      <td colSpan={2} rowSpan={2}>
                        {"HK$ " +
                          Intl.NumberFormat("en-HK").format(record.sub_total)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} rowSpan={4}>
                          {/* <div>{textToHtmlBR(record.terms_conditions)}</div> */}
                          <div style={{fontSize:'10pt'}}>For and on behalf of</div>
                          <div style={{fontWeight:'bold'}}>{record.company?.company_name_en}</div>
                          <div style={{fontSize:'10pt'}}>Received by,</div>
                          <div style={{fontWeight:'bold'}}>{record.customer?.name}</div>
                          <div style={{display:'block',height:'25mm'}}></div>
                          <div><hr /></div>
                          <div style={{fontSize:'10pt'}}>Authorized Signatory with Company chop</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Discount</td>
                      <td colSpan={2}>
                        {record.discount_percent
                          ? record.discount + "%"
                          : "HK$ " +
                            Intl.NumberFormat("en-HK").format(record.discount)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} rowSpan={2}>
                        Total
                      </td>

                      <td colSpan={2} rowSpan={2}>
                        <b>
                          {" "}
                          HK$ {Intl.NumberFormat("en-HK").format(record.total)}
                        </b>
                      </td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
              <div className="thanks_row">
                <div className="thanks_font">Thank you for your business!</div>
                <div className="thanks_notes">
                  Should you have any enquiries concerning this Quotation, please
                  contact {record.company?.contact} on {record.company?.tel}
                </div>
                <div>
                  <hr />
                </div>
                <div className="company_address">
                  {record.company?.company_name_en}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
