import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  Radio,
  Modal,
  InputNumber,
  AutoComplete,
} from "antd";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import { useStateContext } from "../../Store/StateProvider";
import { reloadtAction as producReloadtAction } from "../../Reducers/product_reducer";
import { useLocation, useNavigate } from "react-router-dom";


export default function NewProduct(props: any) {
  let [{ product }, dispatch] = useStateContext();
  let currentID = product.currentID;

  let [unit, setUnit] = useState([]);

  let location = useLocation();
  let netvigate = useNavigate();

  let isNew = location.pathname.indexOf("/product/new") > 0;

  // for outsite add customer
  if (props.productAddType === "new") {
    isNew = true;
  }

  // Modal mode for in modual.
  let modal_mode = props.modal_mode;

  const [form] = Form.useForm();

  useEffect(() => {
    if (!isNew || location.search === "?copy")
      axios
        .get("/api/products", {
          params: { searchField: "id", searchContent: currentID },
        })
        .then((v) => v.data)
        .then((v) => {
          if (v.success) {
            form.setFieldsValue({ ...v.data[0][0] });
          } else {
            Modal.error({ title: "Server internal error." });
          }
        })
        .catch((e) => {
          Modal.error({ title: "Network error." });
        });
  }, [currentID, form, isNew, location.search]);

  useEffect(() => {
    axios
      .get("/api/product_unit")
      .then((v) => v.data)
      .then((v) => {
        if (v.success) {
          setUnit(v.data);
        } else {
          Modal.error({ title: "Server internal error." });
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }, []);

  function updateProduct(pv: any) {
    axios
      .put("/api/product", pv)
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            if (location.pathname.indexOf("/invoice") > 0||location.pathname.indexOf("/quotation") > 0||location.pathname.indexOf("/dnote") > 0) {
              // this is for invoice add CS.
              let {items,index,countAmount,setItems}=props
              items[index].price = pv.price;
              items[index].item_details = pv.name;
              countAmount(items[index]);
              items[index].product = v.data.data;
              setItems([...items]);
              props.closeModal(v.data.data);
            } else {
              netvigate(-1);
            }
          } else {
            Modal.error({
              title: "Update product failed.",
              content: JSON.stringify(v.data.msg),
            });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  function submitNewProduct(pv: any) {
    axios
      .post("/api/product", pv)
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            //Modal.info({ title: "Add user success" });
            dispatch(
              producReloadtAction({
                ...product,
                orderBy: "id",
                sortBy: "DESC",
                searchField: "",
                searchContent: "",
                pageNo: 1,
              })
            );
            if (location.pathname.indexOf("/invoice") > 0||location.pathname.indexOf("/quotation") > 0||location.pathname.indexOf("/dnote") > 0) {
              // this is for invoice add CS.
              //props.getProducts("");
              //props.form.setFieldsValue({ customer: v.data.data });
              //props.getCustomer(v.data.data);
              let {items,index,countAmount,setItems}=props
              items[index].price = pv.price;
              items[index].item_details = pv.name;
              countAmount(items[index]);
              items[index].product = v.data.data;
              setItems([...items]);
              props.closeModal(v.data.data);
            } else {
              netvigate("/main/product");
            }
          } else {
            Modal.error({
              title: "Add product failed.",
              content: JSON.stringify(v.data.msg),
            });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Network error." });
      });
  }

  return (
    <>
      <Form
        name="product"
        form={form}
        labelCol={{ span: 3, style: { textAlign: "left" } }}
        wrapperCol={{ span: 19 }}
        initialValues={{ type: "service", enabled: true }}
        onFinish={(v) => {
          isNew ? submitNewProduct(v) : updateProduct(v);
        }}
      >
        <Form.Item name="id" label="id" style={{ display: "none" }}>
          <Input></Input>
        </Form.Item>
        <Form.Item label="Type" name="type">
          <Radio.Group defaultValue="service" value="service">
            <Radio key="1" value="goods">
              Goods
            </Radio>
            <Radio key="2" value="service">
              Service
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, min: 4 }]}
          hasFeedback
        >
          <Input></Input>
        </Form.Item>
        <Form.Item label="Unit" name="unit">
          <AutoComplete options={unit}></AutoComplete>
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, type: "number", min: 0 }]}
          hasFeedback
        >
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
          />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea rows={2}></TextArea>
        </Form.Item>
        <Form.Item label="Enabled" name="enabled" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 3 }}>
          {isNew ? (
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 16 }}
            >
              Submit
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 16 }}
            >
              Update
            </Button>
          )}
          <Button
            type="ghost"
            onClick={() => {
              if (modal_mode) {
                props.closeModal();
              } else {
                netvigate(-1);
              }
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
