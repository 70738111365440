import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  Radio,
  Modal,
  Tabs,
  Table,
  AutoComplete,
  Checkbox,
  message,
} from "antd";

import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import { useStateContext } from "../../Store/StateProvider";
import { reloadtAction as customerReloadtAction } from "../../Reducers/customer_reducer";
import Contact from "../../Entity/Contact";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";

export default function NewCustomer(props: any) {
  let [{ customer }, dispatch] = useStateContext();
  let currentID = customer.currentID;

  let [contacts, setContacts] = useState([{}]);
  let [addresses, setAddresses] = useState([{}]);
  let [showCellBorderRed, setShowCellBorderRed] = useState([{}]);

  let location = useLocation();
  let netvigate = useNavigate();
  let isNew = location.pathname.indexOf("customer/new") > 0;

    // for outsite add customer
  if (props.customerAddType === "new") {  
    isNew = true;
  }

  // Modal mode for in modual.
  let modal_mode = props.modal_mode;

  const [form] = Form.useForm();

  useEffect(() => {
    // if is not add new record then load old record, otherwise does not load record.

    if (!isNew || location.search === "?copy")
      axios
        .get("/api/customer", {
          params: { id: currentID },
        })
        .then((v) => v.data)
        .then((v) => {
          if (v.success) {
            form.setFieldsValue({ ...v.data });
            setContacts(v.data.contacts);
            setAddresses(v.data.addresses);
          } else {
            Modal.error({ title: "Server internal error." });
          }
        })
        .catch((e) => {
          Modal.error({ title: "Network error." });
        });
  }, [currentID, form, isNew, location.search]);

  function showError(json: [any]) {
    showCellBorderRed = [];
    if (json.length > 0) {
      for (let i in json) {
        if (
          (json[i] as any).property === "addresses" ||
          (json[i] as any).property === "contacts"
        ) {
          for (let j in (json[i] as any).children) {
            for (let k in (json[i] as any).children[j].children) {
              showCellBorderRed.push(
                `${(json[i] as any).property}-${
                  (json[i] as any).children[j].property
                }-${(json[i] as any).children[j].children[k].property}`
              );
            }
          }
        }
      }
    }
    setShowCellBorderRed([...showCellBorderRed]);
  }

  function update(v: any) {
    contacts = contacts.filter((item) => Object.keys(item).length > 0);
    addresses = addresses.filter((item) => Object.keys(item).length > 0);
    setContacts([...contacts]);
    setAddresses([...addresses]);
    v.contacts = contacts;
    v.addresses = addresses;
    axios
      .put("/api/customer", v)
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            if (location.pathname.indexOf("/invoice") > 0) {
              // this is for invoice add CS.
              props.getCustomers("");
              props.form.setFieldsValue({ customer: v.data.data });
              props.getCustomer(v.data.data);
              props.closeModal();
            } else {
              // this is for normal cs page.
              netvigate(-1);
            }
          } else {
            showError(v.data.msg);
            message.warning("Input data error.");
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: ("" + e) as string });
      });
  }

  function submitNew(v: any) {
    contacts = contacts.filter((item) => Object.keys(item).length > 0);
    addresses = addresses.filter((item) => Object.keys(item).length > 0);
    setContacts([...contacts]);
    setAddresses([...addresses]);
    v.contacts = contacts;
    v.addresses = addresses;
    axios
      .post("/api/customer", v)
      .then((v) => {
        if (v.status === 200) {
          if (v.data.success) {
            //Modal.info({ title: "Add user success" });
            dispatch(
              customerReloadtAction({
                ...customer,
                orderBy: "id",
                sortBy: "DESC",
                searchField: "",
                searchContent: "",
                pageNo: 1,
              })
            );
            if (location.pathname.indexOf("/invoice") > 0) {
              // this is for invoice add cs.
              props.getCustomers("");
              props.form.setFieldsValue({ customer: v.data.data });
              props.getCustomer(v.data.data);
              props.closeModal();
            } else {
              // Following for customer page.
              netvigate("/main/customer");
            }
          } else {
            showError(v.data.msg);
            message.warning("Input data error.");
            // Modal.error({
            //   title: "Add Customer failed.",
            //   content: JSON.stringify(v.data.msg),
            // });
          }
        }
      })
      .catch((e) => {
        Modal.error({ title: "Intenal or Network error." + e.toString() });
      });
  }

  function changeCellValue(
    value: any,
    index: number,
    column_name: string,
    state: any,
    setState: any
  ) {
    if (value === "") {
      delete state[index][column_name];
    } else {
      state[index][column_name] = value;
    }
    setState([...state]);
  }

  let contact_column = [
    {
      title: "SALUTATION",
      dataIndex: "salutaion",
      render: (text: any, record: any, index: any) => (
        <AutoComplete
          placeholder="Salutation"
          value={record.salutaion}
          style={
            showCellBorderRed.includes(`contacts-${index}-salutaion`)
              ? {
                  border: 1,
                  borderStyle: "solid",
                  borderColor: "red",
                  width: "100%",
                }
              : { width: "100%" }
          }
          options={[
            { value: "Mr." },
            { value: "Ms." },
            { value: "Mrs." },
            { value: "Miss." },
            { value: "Dr." },
          ]}
          onChange={(v) =>
            changeCellValue(v, index, "salutaion", contacts, setContacts)
          }
        ></AutoComplete>
      ),
    },
    {
      title: "FIRST NAME",
      dataIndex: "first_name",
      render: (text: any, record: any, index: any) => (
        <Input
          style={
            showCellBorderRed.includes(`contacts-${index}-first_name`)
              ? { border: 1, borderStyle: "solid", borderColor: "red" }
              : {}
          }
          value={record.first_name}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "first_name",
              contacts,
              setContacts
            )
          }
        ></Input>
      ),
    },
    {
      title: "LAST NAME",
      dataIndex: "last_name",
      render: (text: any, record: any, index: any) => (
        <Input
          style={
            showCellBorderRed.includes(`contacts-${index}-last_name`)
              ? { border: 1, borderStyle: "solid", borderColor: "red" }
              : {}
          }
          value={record.last_name}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "last_name",
              contacts,
              setContacts
            )
          }
        ></Input>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      render: (text: any, record: any, index: any) => (
        <Input
          style={
            showCellBorderRed.includes(`contacts-${index}-email`)
              ? { border: 1, borderStyle: "solid", borderColor: "red" }
              : {}
          }
          value={record.email}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "email",
              contacts,
              setContacts
            )
          }
        ></Input>
      ),
    },
    {
      title: "TEL",
      dataIndex: "tel",
      render: (text: any, record: any, index: any) => (
        <Input
          value={record.tel}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "tel",
              contacts,
              setContacts
            )
          }
        ></Input>
      ),
    },
    {
      title: "MOBILE",
      dataIndex: "mobile",
      render: (text: any, record: any, index: any) => (
        <Input
          value={record.mobile}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "mobile",
              contacts,
              setContacts
            )
          }
        ></Input>
      ),
    },
    {
      title: "FAX",
      dataIndex: "fax",
      render: (text: any, record: any, index: any) => (
        <Input
          value={record.fax}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "fax",
              contacts,
              setContacts
            )
          }
        ></Input>
      ),
    },
    {
      title: "DESIGNATION",
      dataIndex: "designation",
      render: (text: any, record: any, index: any) => (
        <Input
          value={record.designation}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "designation",
              contacts,
              setContacts
            )
          }
        ></Input>
      ),
    },
    {
      title: "DEPARTMENT",
      dataIndex: "department",
      render: (text: any, record: any, index: any) => (
        <Input
          value={record.department}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "department",
              contacts,
              setContacts
            )
          }
        ></Input>
      ),
    },

    {
      title: "",

      render: (text: any, record: any, index: any) => (
        <DeleteOutlined
          style={{ margin: 8, color: "red" }}
          onClick={() => {
            contacts = contacts.filter((item) => {
              return item !== contacts[index];
            });
            setContacts([...contacts]);
          }}
        />
      ),
    },
  ];
  let address_column = [
    {
      title: "ATTENTION",
      dataIndex: "attention",
      render: (text: any, record: any, index: any) => (
        <Input
          style={
            showCellBorderRed.includes(`addresses-${index}-attention`)
              ? { border: 1, borderStyle: "solid", borderColor: "red" }
              : {}
          }
          value={record.attention}
          onChange={(e) => {
            changeCellValue(
              e.currentTarget.value,
              index,
              "attention",
              addresses,
              setAddresses
            );
          }}
        ></Input>
      ),
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
      render: (text: any, record: any, index: any) => (
        <Input
          style={
            showCellBorderRed.includes(`addresses-${index}-address`)
              ? { border: 1, borderStyle: "solid", borderColor: "red" }
              : {}
          }
          value={record.address}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "address",
              addresses,
              setAddresses
            )
          }
        ></Input>
      ),
    },
    {
      title: "TEL",
      dataIndex: "tel",
      render: (text: any, record: any, index: any) => (
        <Input
          value={record.tel}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "tel",
              addresses,
              setAddresses
            )
          }
        ></Input>
      ),
    },
    {
      title: "FAX",
      dataIndex: "fax",
      render: (text: any, record: any, index: any) => (
        <Input
          value={record.fax}
          onChange={(e) =>
            changeCellValue(
              e.currentTarget.value,
              index,
              "fax",
              addresses,
              setAddresses
            )
          }
        ></Input>
      ),
    },

    {
      title: "Billing ADDRESS",
      dataIndex: "default_billing_address",
      render: (text: any, record: any, index: any) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            margin: 0,
            textAlign: "center",
          }}
        >
          <Checkbox
            checked={record.default_billing_address}
            onChange={(e) => {
              for (let i in addresses) {
                (addresses[i] as any)["default_billing_address"] = false;
              }

              changeCellValue(
                e.target.checked,
                index,
                "default_billing_address",
                addresses,
                setAddresses
              );
            }}
          ></Checkbox>
        </div>
      ),
    },
    {
      title: "SHIPPING ADDRESS",
      dataIdex: "default_shipping_address",
      render: (text: any, record: any, index: any) => (
        <div
          style={{
            width: "100%",
            height: "100%",
            margin: 0,
            textAlign: "center",
          }}
        >
          <Checkbox
            checked={record.default_shipping_address}
            onChange={(e) => {
              for (let i in addresses) {
                (addresses[i] as any)["default_shipping_address"] = false;
              }
              changeCellValue(
                e.target.checked,
                index,
                "default_shipping_address",
                addresses,
                setAddresses
              );
            }}
          ></Checkbox>
        </div>
      ),
    },

    {
      title: "",

      render: (text: any, record: any, index: any) => (
        <DeleteOutlined
          style={{ margin: 8, color: "red" }}
          onClick={() => {
            addresses = addresses.filter((item) => {
              return item !== addresses[index];
            });
            setAddresses([...addresses]);
          }}
        />
      ),
    },
  ];

  return (
    <>
      {props.csType && (
        <div style={{ fontWeight: "bold" }}>
          {(props.csType as String).toUpperCase()} CUSTOMER
        </div>
      )}
      <Form
        name="form"
        form={form}
        labelCol={{ span: 3, style: { textAlign: "left" } }}
        wrapperCol={{ span: 19 }}
        initialValues={{ type: "company", enabled: true }}
        onFinish={(v: any) => {
          isNew ? submitNew(v) : update(v);
        }}
      >
        <Form.Item name="id" label="id" style={{ display: "none" }}>
          <Input></Input>
        </Form.Item>
        <Form.Item label="Type" name="type">
          <Radio.Group>
            <Radio key="1" value="company">
              Company
            </Radio>
            <Radio key="2" value="individual">
              Individual
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, min: 3 }]}
          hasFeedback
        >
          <Input></Input>
        </Form.Item>

        <Form.Item
          label="Primary Contact"
          name="primary_contact"
          rules={[{ required: true, min: 3 }]}
          hasFeedback
        >
          <Input></Input>
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
          <Input></Input>
        </Form.Item>
        <Form.Item label="Tel" name="tel">
          <Input></Input>
        </Form.Item>
        <Form.Item label="Fax" name="fax">
          <Input></Input>
        </Form.Item>
        <Form.Item label="Website" name="website">
          <Input></Input>
        </Form.Item>
        <Form.Item label="Remaik" name="remark">
          <TextArea rows={2}></TextArea>
        </Form.Item>
        <Form.Item label="Enabled" name="enabled" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Tabs style={{ marginBottom: "16px" }}>
          <Tabs.TabPane tab="Contacts" key="1">
            <Button
              type="primary"
              style={{
                marginBottom: 16,
              }}
              onClick={() => {
                setContacts([...contacts, {} as Contact]);
              }}
            >
              Add contact row
            </Button>
            <Table
              size="small"
              rowKey="id"
              dataSource={contacts}
              columns={contact_column}
              bordered
              pagination={false}
              rowClassName="table-cell-magin-0"
            ></Table>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Address" key="2">
            <Button
              type="primary"
              style={{
                marginBottom: 16,
              }}
              onClick={() => {
                setAddresses([...addresses, {}]);
              }}
            >
              Add address row
            </Button>
            <Table
              rowKey="id"
              dataSource={addresses}
              columns={address_column}
              bordered
              pagination={false}
              rowClassName="table-cell-magin-0"
            ></Table>
          </Tabs.TabPane>
        </Tabs>

        <Form.Item>
          <div style={{ marginTop: 24 }}>
            {isNew ? (
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 16 }}
              >
                Submit
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginRight: 16 }}
              >
                Update
              </Button>
            )}
            <Button type="ghost" onClick={() => {
              if(modal_mode){
                props.closeModal()
              }else{
                netvigate(-1)
              }

              
              }}>
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}
