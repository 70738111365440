import React from "react";
import { Form, Input, Button, Modal } from "antd";
import { useStateContext } from "../Store/StateProvider";
import axios from "axios";
import { setLoginStateAction } from "../Reducers/login_reducer";
import {  useNavigate } from "react-router-dom";

const Login = (props: any) => {  
  const [, dispatch] = useStateContext();
  let netvigate = useNavigate()
  console.log('login page')
  const onFinish = (values:any) => {
    axios.post("/api/login", values).then(v => {
      if (v.status === 200) {
        if (v.data.success) {
          dispatch(setLoginStateAction({state:'true',user:v.data.data}));
          netvigate('/',{replace:true})
          } else {
          Modal.error({
            title: "Error",
            content: v.data.msg
          });
        }
      }
   
    });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 }
  };

  return (
    <div style={{ margin: "0 auto", marginTop: 50, width: 400 }}>
      <Form
        {...layout}
        name="basic"       
        onFinish={onFinish}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true,min:3}]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true,min:3,}]}
        >
          <Input.Password />
        </Form.Item>


        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Login;
