
export  function reducer(state: any, action: any) {
    switch (action.type) {
        case 'CUSTOMER_RELOAD':
            return { ...action.playload }
        default:
            return state
    }
}

export function reloadtAction(object:any){
    return {type:'CUSTOMER_RELOAD',playload:object}
}