import React, { useEffect, useState, useRef, useMemo } from "react";
import axios from "axios";
import { Modal, Table, Button, Form, Input, Select, Switch } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { getDataForTable } from "../../Utils/getDataForTable";


export default function  User(props: any){
  console.log("user");
  // table data
  let [data, setData] = useState(null as [{[any:string]:any}]|null);
  let [pageTotal, setPageTotal] = useState();
  
  let [pageNo, setPageNo] = useState(1);
  let [pageSize] = useState(8); 
  let [searchField, setSearchField] = useState([""]);
  let [searchContent, setSearchContent] = useState([""]);
  let [between] = useState([]);
  let [conditionType, setConditionType] = useState("and");
  let [queryType, setQueryType] = useState("like");
  let [orderBy, setOrderBy] = useState("id");
  let [sortBy, setSortBy] = useState("DESC");
  // end table data

  // let [
  //   pageNo,
  //   setPageNo,
  //   pageSize,
  //   setPageSize,
  //   searchField,
  //   setSearchField,
  //   searchContent,
  //   setSearchContent,
  //   between,
  //   setBetween,
  //   conditionType,
  //   setConditionType,
  //   queryType,
  //   setQueryType,
  //   orderBy,
  //   setOrderBy,
  //   sortBy,
  //   setSortBy,
  //   // pageTotal,
  //   // setPageTotal,
  //   // data,
  //   // setData
  // ] = useTableParms(props.location.search);

  let [userModalVisiable, setUserModalVisiable] = useState(false);
  let [user, setUser] = useState({
    id: 0,
    user_name: "",
    login_name: "",
    password: "",
    group: "user",
    enabled: true,
    type: "Add User"
  });
  let [addBtnLoading, setAddBtnLoading] = useState(false);
  let [editBtnLoading, setEditBtnLoading] = useState(false);
  let [delBtnLoading, setDelBtnLoading] = useState(false);
  let [showAllBtn, setShowAllBtn] = useState(true);
  const searchRef = useRef(null) as any;

  // Memo fetch table data.
  const fetchTableData = useMemo(
    () => () =>
      getDataForTable(
        {
          pageSize,
          pageNo,
          searchField,
          searchContent,
          between,
          conditionType,
          queryType,
          orderBy,
          sortBy
        },
        setData,
        setPageTotal,
        "/api/users",
        "GET"
      ),
    [
      pageSize,
      pageNo,
      searchField,
      searchContent,
      between,
      conditionType,
      queryType,
      orderBy,
      sortBy
    ]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  let columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
     
      sorter: true
    },
    {
      title: "Login Name",
      dataIndex: "login_name",
     
      sorter: true
    },
    {
      title: "Password",
      dataIndex: "password",
     
    },
    {
      title: "Group",
      dataIndex: "group",
    
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
    
      sorter: true,
      render: (text: any, record: any, index: any) => {
        if (record.enabled) {
          return <CheckOutlined />;
        }
      }
    },
    {
      title: "Action",
      dataIndex: "id",
    
      render: (text: any, record: any, index: any) => (
        <Button
          onClick={() => {
            console.log(record);
            setUser({ ...user, ...record, type: "Edit User" });
            setEditBtnLoading(false);
            setDelBtnLoading(false);
            setUserModalVisiable(true);
          }}
        >
          Edit
        </Button>
      )
    }
  ];

  // Form onFinish function.
  function updateOrAddUser(v: any) {
    if (user.type === "Add User") {
      // add user
      setAddBtnLoading(true);
      axios
        .post("/api/user", v)
        .then(v => {
          if (v.status === 200) {
            if (v.data.success) {
              //Modal.info({ title: "Add user success" });
              setUserModalVisiable(false);
              fetchTableData();
            } else {
              Modal.error({
                title: "Add user failed.",
                content: JSON.stringify(v.data.msg)
              });
              setUserModalVisiable(false);
            }
          }
        })
        .catch(e => {
          Modal.error({ title: "Add user failed." });
        });
    } else {
      // edituser

      setEditBtnLoading(true);
      axios
        .put("/api/user", v)
        .then(v => {
          if (v.status === 200) {
            if (v.data.success) {
              //Modal.info({ title: "Add user success" });
              setUserModalVisiable(false);
              fetchTableData();
            } else {
              Modal.error({
                title: "Edit user failed.",
                content: JSON.stringify(v.data.msg)
              });
            }
            setEditBtnLoading(false);
          }
        })
        .catch(e => {
          Modal.error({ title: "Edit user failed." });
          setEditBtnLoading(false);
        });
    }
  }

  // Delete user
  function delUser(userid: number) {
    axios
      .delete("/api/user", { data: { id: userid } })
      .then(v => {
        if (v.status === 200) {
          if (v.data.success) {
            Modal.info({ title: "User deleted." });
            fetchTableData();
            setUserModalVisiable(false);
            setDelBtnLoading(false);
          } else {
            Modal.error({ title: "Error", content: v.data.msg });
          }
        } else {
          Modal.error({ title: "Server error" });
        }
      })
      .catch(e => {
        Modal.error({ title: "network error" });
      });
  }

  function tableChange(pagination: any, filters: any, sorter: any) {
    // handle table sort.----------------
    let orderby: string = "";
    let order: any = "ASC";
    if (sorter.column) {
      orderby = sorter.field;
      order = sorter.order === "descend" ? "DESC" : "ASC";
    } else {
      orderby = "";
      order = "ASC";
    }
    // end handel table sort--------------^

    setPageNo(pagination.current);
    setOrderBy(orderby);
    setSortBy(order);
  }

  return (
    <>
      {
        // Modal start.
      }
      <Modal
        title={user.type}
        visible={userModalVisiable}
        onCancel={() => {
          setUserModalVisiable(false);
        }}
        footer={false}
        destroyOnClose
      >
        <div>
          <Form
            name="user"
            labelCol={{ span: 5, style: { textAlign: "left" } }}
            wrapperCol={{ span: 19 }}
            onFinish={updateOrAddUser}
            initialValues={user}
          >
            <Form.Item name="id" label="id" style={{ display: "none" }}>
              <Input value={user.id}></Input>
            </Form.Item>
            <Form.Item
              label="User Name"
              name="user_name"
              rules={[{ required: true, min: 5 }]}
              hasFeedback
            >
              <Input ></Input>
            </Form.Item>
            <Form.Item
              label="Login Name"
              name="login_name"
              rules={[{ required: true, min: 5 }]}
              hasFeedback
            >
              <Input></Input>
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, min: 5 }]}
              hasFeedback
            >
              <Input.Password ></Input.Password>
            </Form.Item>
            <Form.Item
              label="Group"
              name="group"
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select >
                <Select.Option key="1" value="user">
                  user
                </Select.Option>
                <Select.Option key="2" value="admin">
                  admin
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Enable" name="enabled" valuePropName="checked">
              <Switch defaultChecked={user.enabled} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
              {user.type === "Add User" ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 16 }}
                  loading={addBtnLoading}
                >
                  Submit
                </Button>
              ) : (
                <>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 16 }}
                    loading={editBtnLoading}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    danger
                    onClick={e => {
                      setDelBtnLoading(true);
                      delUser(user.id);
                    }}
                    loading={delBtnLoading}
                  >
                    Delete
                  </Button>
                </>
              )}
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {
        // Modal end.
      }
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button
            style={{ marginRight: 8 }}
            type="primary"
            onClick={() => {
              setUser({
                id: 0,
                user_name: "",
                login_name: "",
                password: "",
                group: "user",
                enabled: true,
                type: "Add User"
              });
              setAddBtnLoading(false);
              setUserModalVisiable(true);
            }}
          >
            NEW
          </Button>{" "}
          <Search
            ref={searchRef}
            placeholder="User Name / Login Name"
            onSearch={value => {
              setShowAllBtn(!setShowAllBtn);
              setPageNo(1);
              setSearchField(["user_name", "login_name"]);
              setSearchContent([value, value]);
              setConditionType("or");
              setQueryType("like");
            }}
            style={{ width: 200, marginRight: 32 }}
          />{" "}
          <Button
            disabled={showAllBtn}
            onClick={() => {
              setShowAllBtn(true);
              searchRef.current.state.value = "";
              setSearchContent([]);
              setSearchField([]);
            }}
          >
            Show All
          </Button>         
        </div>
        <Table
        rowKey={(record)=>record.id}
          bordered
          tableLayout="fixed"
          columns={columns}
          dataSource={data?data:[]}
          loading={data?false:true}
          pagination={{ current: pageNo, total: pageTotal, pageSize: pageSize }}
          onChange={tableChange}
          style={{ minHeight: 500 }}
        ></Table>
      </div>
    </>
  );
};
