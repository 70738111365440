import React from "react";
import { Result, Button } from "antd";

export default () => {
  return (
    <Result
      status="warning"
      title="There are some problems with your Server."
      extra={
        <Button type="primary" onClick={() => window.history.back() }>Reload</Button>
      }
    ></Result>
  );
};
