
export const checkLoginReducer=(state:any,action:any)=>{

    switch(action.type){
        case 'CHECK_LOGIN':
            return action.playload
        default:
            return state
    }
}

export const setLoginStateAction=({state,user}:{state:'true'|'false'|'error'|'loading',user?:string})=>{
    if(user===undefined){
        user=''
    }
   return {
       type:'CHECK_LOGIN',
       playload:{state,user}
   }
}