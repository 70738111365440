import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Menu,
  Breadcrumb,
  Col,
  Row,
  Button,
  Dropdown,
  Select,
} from "antd";
import {
  DashboardOutlined,
  AccountBookOutlined,
  PhoneOutlined,
  ShoppingCartOutlined,
  ContactsOutlined,
  UserOutlined,
  HomeOutlined,
  CarOutlined,
} from "@ant-design/icons";
import Logout from "./Logout";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { useStateContext } from "../Store/StateProvider";
import { reloadtAction as producReloadAction } from "../Reducers/product_reducer";
import { reloadtAction as customerReloadAction } from "../Reducers/customer_reducer";
import { reloadtAction as invoiceReloadAction } from "../Reducers/invoice_reducer";
import { reloadtAction as quotationReloadAction } from "../Reducers/quotation_reducer";
import { reloadtAction as dnoteReloadAction } from "../Reducers/dnote_reducer";
import { reloadtAction as companyReloadAction } from "../Reducers/company_reducer";

const { Header, Content, Footer, Sider } = Layout;

export default function Main(props: any) {
  let [collapsed, setCollapse] = useState(false);
  let [state, dispatch] = useStateContext();
  let navigate = useNavigate();
  let location = useLocation();
  console.log("main page location:" + location.pathname);
  // let select = useRef(null) as any;
  // let left_menu_ref =useRef(null) as any;

  console.log("main page");
  // Breadcrumb splite url {
  let current_path: string = window.location.href;
  let breadcrumb_path_array: Array<string> = current_path
    .substring(current_path.indexOf("main") + 5, current_path.length + 1)
    .split("/");
  let firstPath = breadcrumb_path_array[0];
  // Breadcrumb end }

  // for search select menu.
  let [pathState, setPathState] = useState(firstPath);

  // for top seach cataloge for examle:'invoice','qutation'當你點擊search生效.
  let [searchCat, setSearchCat] = useState("invoice");

  //hightlight menu item
  let [leftMenuKey, setLeftMenuKey] = useState("");

  useEffect(() => {
    //  1 page reload get first path,
    //  2 set search select box option to match current page. and change search select box will change pathState
    //  3 search content depanse on pathState.

    // depanse on path to select left menu.
    setPathState(firstPath);
    switch (firstPath) {
      case "invoice":
        setLeftMenuKey("1");
        break;
      case "quotation":
        setLeftMenuKey("2");
        break;
      case "dnote":
        setLeftMenuKey("3");
        break;
      case "product":
        setLeftMenuKey("4");
        break;
      case "customer":
        setLeftMenuKey("5");
        break;
      case "company":
        setLeftMenuKey("6");
        break;
      case "file":
        setLeftMenuKey("7");
        break;
      case "user":
        setLeftMenuKey("8");
        break;
      default:
        setLeftMenuKey("0");
    }
  }, [firstPath]);

  const drop_menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          navigate("invoice/new");
          //  setLeftMenuKey("1");
        }}
      >
        + Invoice
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          navigate("quotation/new");
          //  setLeftMenuKey("2");
        }}
      >
        + Quotation
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          navigate("dnote/new");
          //  setLeftMenuKey("3");
        }}
      >
        + Delivery Note
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          navigate("product/new");
          //   setLeftMenuKey("4");
        }}
      >
        + Product
      </Menu.Item>
      <Menu.Item
        key="5"
        onClick={() => {
          navigate("customer/new");
          // setLeftMenuKey("5");
        }}
      >
        + Customer
      </Menu.Item>
      <Menu.Item
        key="6"
        onClick={() => {
          navigate("company/new");
          //   setLeftMenuKey("6");
        }}
      >
        + Company
      </Menu.Item>
    </Menu>
  );

  const selectBefore = (
    // for search netvigate link.
    <Select
      value={
        [
          "customer",
          "invoice",
          "quotation",
          "product",
          "company",
          "dnote",
        ].includes(pathState)
          ? pathState
          : "invoice"
      }
      style={{ width: 120 }}
      bordered={false}
      onChange={(v, o) => {
        setPathState(v);
        setSearchCat(v);
      }}
    >
      <Select.Option value="customer">Customer</Select.Option>
      <Select.Option value="invoice">Invoice</Select.Option>
      <Select.Option value="quotation">Quotation</Select.Option>
      <Select.Option value="dnote">Delivery Note</Select.Option>
      <Select.Option value="product">Product</Select.Option>
      <Select.Option value="company">Company</Select.Option>
    </Select>
  );

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={() => setCollapse(!collapsed)}
        >
          <div
            style={{
              height: 32,
              background: "rgba(255, 255, 255, 0.2)",
              margin: 16,
              textAlign: "left",
              fontFamily: "Helvetica,arial,sans-serif",
              paddingLeft: 10,
              fontSize: "14pt",
              fontWeight: "bold",
              color: "white",
            }}
          >
            LJN Studio
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={[leftMenuKey]}>
            <Menu.Item
              key={0}
              onClick={() => {
                //  setLeftMenuKey("0");
                setSearchCat(""); // set search select value
                navigate("dashboard");
              }}
            >
              <DashboardOutlined />
              <span>Dashboard</span>
            </Menu.Item>
            <Menu.Item
              key={1}
              onClick={() => {
                //    setLeftMenuKey("1");
                setSearchCat("invoice");
                navigate("invoice");
              }}
            >
              <AccountBookOutlined />
              <span>Invoice</span>
            </Menu.Item>

            <Menu.Item
              key={2}
              onClick={() => {
                //   setLeftMenuKey("2");
                setSearchCat("quotation");
                navigate("quotation");
              }}
            >
              <PhoneOutlined />
              <span>Quotation</span>
            </Menu.Item>
            <Menu.Item
              key={3}
              onClick={() => {
                //     setLeftMenuKey("3");
                setSearchCat("dnote");
                navigate("dnote");
              }}
            >
              <CarOutlined />
              <span>Delivery Note</span>
            </Menu.Item>
            <Menu.Item
              key={4}
              onClick={() => {
                //     setLeftMenuKey("4");
                setSearchCat("product");
                navigate("product");
              }}
            >
              <ShoppingCartOutlined />
              <span>Product</span>
            </Menu.Item>
            <Menu.Item
              key={5}
              onClick={() => {
                //     setLeftMenuKey("5");
                setSearchCat("customer");
                navigate("customer");
              }}
            >
              <ContactsOutlined />
              <span>Customer</span>
            </Menu.Item>
            <Menu.Item
              key={6}
              onClick={() => {
                //     setLeftMenuKey("6");
                setSearchCat("company");
                navigate("company");
              }}
            >
              <HomeOutlined />
              <span>Company</span>
            </Menu.Item>
            <Menu.Item
              key={7}
              onClick={() => {
                //     setLeftMenuKey("6");
                setSearchCat("file");
                navigate("file");
              }}
            >
              <HomeOutlined />
              <span>Files</span>
            </Menu.Item>
            <Menu.Item
              key={8}
              onClick={() => {
                //       setLeftMenuKey("7");
                navigate("user");
              }}
            >
              <UserOutlined />
              <span>Users</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ background: "#fff", padding: 0 }}>
            {" "}
            <Row>
              <Col span={2} style={{ paddingLeft: 15 }}>
                <Dropdown overlay={drop_menu}>
                  <Button type="primary" shape="circle">
                    <PlusOutlined />
                  </Button>
                </Dropdown>
              </Col>
              <Col span={10}>
                <Search
                  addonBefore={selectBefore}
                  allowClear
                  style={{ display: "inline-block", marginTop: 16 }}
                  onSearch={(v) => {
                    if (v === "") {
                      return;
                    }
                    switch (searchCat) {
                      case "invoice": // for which table for search.
                        dispatch(
                          invoiceReloadAction({
                            ...state.invoice, // state for section.
                            searchField: [
                              "invoice_no",
                              "customer_name",
                              "invoice_date",
                            ], // For which field to search.
                            searchContent: [v, v, v],
                            conditionType: "or",
                            pageNo: 1,
                          })
                        );
                        setLeftMenuKey("1");
                        navigate("invoice");
                        return;
                      case "quotation": // for which table for search.
                        dispatch(
                          quotationReloadAction({
                            ...state.quotation, // state for section.
                            searchField: [
                              "quotation_no",
                              "customer_name",
                              "quotation_date",
                            ], // For which field to search.
                            searchContent: [v, v, v],
                            conditionType: "or",
                            pageNo: 1,
                          })
                        );
                        setLeftMenuKey("2");
                        navigate("quotation");
                        return;
                      case "dnote": // for which table for search.
                        dispatch(
                          dnoteReloadAction({
                            ...state.dnote, // state for section.
                            searchField: [
                              "dnote_no",
                              "customer_name",
                              "dnote_date",
                            ], // For which field to search.
                            searchContent: [v, v, v],
                            conditionType: "or",
                            pageNo: 1,
                          })
                        );
                        setLeftMenuKey("3");
                        navigate("dnote");
                        return;
                      case "product":
                        dispatch(
                          producReloadAction({
                            ...state.product,
                            searchField: ["name", "description"],
                            searchContent: [v, v],
                            conditionType: "or",
                            pageNo: 1,
                          })
                        );
                        setLeftMenuKey("4");
                        navigate("product");
                        return;
                      case "customer":
                        dispatch(
                          customerReloadAction({
                            ...state.customer,
                            searchField: [
                              "name",
                              "primary_contact",
                              "email",
                              "tel",
                              "fax",
                              "website",
                            ],
                            searchContent: [v, v, v, v, v, v],
                            conditionType: "or",
                            pageNo: 1,
                          })
                        );
                        setLeftMenuKey("5");
                        navigate("customer");
                        return;
                      case "company":
                        dispatch(
                          companyReloadAction({
                            ...state.company,
                            searchField: [
                              "company_name_en",
                              "company_name_ch",
                              "contact",
                              "email",
                              "tel",
                              "fax",
                              "website",
                            ],
                            searchContent: [v, v, v, v, v, v, v],
                            conditionType: "or",
                            pageNo: 1,
                          })
                        );
                        setLeftMenuKey("6");
                        navigate("company");
                        return;
                      default:
                        return;
                    }
                  }}
                />
              </Col>
              <Col span={4}></Col>
              <Col
                span={8}
                style={{
                  display: "inline-block",
                  textAlign: "right",
                  paddingRight: 15,
                }}
              >
                <Logout></Logout>
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              {breadcrumb_path_array.map((i) => (
                <Breadcrumb.Item key={i}>{i.toUpperCase()}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <div style={{ padding: 24, background: "#fff", minHeight: 360 }}>
              <Outlet></Outlet>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            LJN Studio ©2022 Created by Daniel
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}
