import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  Alert,
  message,
  Modal,
  Dropdown,
  Menu
} from "antd";
import {
  CheckOutlined,
  DownOutlined,
  DeleteOutlined,
  StopOutlined,
  EditOutlined
} from "@ant-design/icons";
import { getDataForTable } from "../../Utils/getDataForTable";
import "./style.css";
import { useStateContext } from "../../Store/StateProvider";
import { reloadtAction as customerReloadAction } from '../../Reducers/customer_reducer';
import {  useNavigate } from "react-router-dom";

export default function Customers(props: any){
  // table data
  let [data, setData] = useState(null as [{[any:string]:any}]|null);
  let [pageTotal, setPageTotal] = useState();
  let [ids, setIds] = useState([]);
  let [state, dispatch] = useStateContext();
  let netvigate =useNavigate()
 

  let [
    pageSize,
    pageNo,
    searchField,
    searchContent,
    between,
    conditionType,
    queryType,
    orderBy,
    sortBy,
  ] = Object.values(state.customer);

  const fetchTableData = useMemo(
    () => () =>
      getDataForTable(
        {
          pageSize,
          pageNo,
          searchField,
          searchContent,
          between,
          conditionType,
          queryType,
          orderBy,
          sortBy
        },
        setData,
        setPageTotal,
        "/api/customers",
        "GET"
      ),
    [
      pageSize,
      pageNo,
      searchField,
      searchContent,
      between,
      conditionType,
      queryType,
      orderBy,
      sortBy
    ]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  function tableChange(pagination: any, filters: any, sorter: any) {
    // handle table sort.----------------

    let orderby: string = "";
    let order: any = "DESC";
    if (sorter.column) {
      orderby = sorter.field;
      order = sorter.order === "descend" ? "DESC" : "ASC";
    } else {
      orderby = "id";
      order = "DESC";
    }

    // end handel table sort--------------^
    dispatch(
      customerReloadAction({
        ...state.customer,
        pageNo: pagination.current,
        orderBy: orderby,
        sortBy: order,
        pageSize:pagination.pageSize
      })
    );
  }
  function deleteRecord(id: any, bulk: boolean = false) {
    axios
      .delete(bulk ? "/api/customer_bulk_delete" : "/api/customer", {
        data: { id: id }
      })
      .then(v => {
        console.log(v);
        if (v.status === 200) {
          if (v.data.success) {
            fetchTableData();

            setIds([]);
            message.success("Delete success.");
          } else {
            Modal.error({ title: "Error", content: JSON.stringify(v.data.msg) });
          }
        } else {
          Modal.error({ title: "Server error" });
        }
      })
      .catch(e => {
        Modal.error({ title: "network error" });
      });
  }

  function disEnCustomer(id: any, enabled: boolean, isBulk: boolean = false) {
    axios
      .put(isBulk ? "/api/customer_bulk_disable" : "/api/customer", {
        id: id,
        enabled: enabled
      })
      .then(v => {
        if (v.status === 200) {
          if (v.data.success) {
            fetchTableData();
            setIds([]);
            message.success("Action success.");
          } else {
            Modal.error({
              title: "error.",
              content: JSON.stringify(v.data.msg)
            });
          }
        }
      })
      .catch(e => {
        Modal.error({ title: "Network error." });
      });
  }

  const action_menu = (
    <Menu>
      {ids.length === 1 && (
        <Menu.Item key="edit"
        onClick={() => {
          if (ids.length !==1) {
            Modal.info({
              title: "Error",
              content: "Select one customer first."
            });
          } else {
            dispatch(customerReloadAction({...state.customer,currentID:ids[0]}))
            netvigate("/main/customer/edit");

          }
        }}>
          <EditOutlined />
          Edit
        </Menu.Item>
      )}
      <Menu.Item
        key="delete"
        onClick={() => {
          Modal.confirm({
            title: "Are you confirm to delete?",
            onOk() {
              deleteRecord(ids, true);
            }
          });
        }}
      >
        <DeleteOutlined />
        Delete
      </Menu.Item>
      <Menu.Item key="disable" onClick={() => disEnCustomer(ids, false, true)}>
        <StopOutlined />
        Disable
      </Menu.Item>
      <Menu.Item key="enable" onClick={() => disEnCustomer(ids, true, true)}>
        <CheckOutlined />
        Enable
      </Menu.Item>
    </Menu>
  );

  let columns1 = [
    {
      title: "Type",
      dataIndex: "type",
      sorter: true,
      //  key:'type'  ,
      sortOrder:
        orderBy === "type"
          ? sortBy === "ASC"
            ? "ascend"
            : ("descend" as "descend" | "ascend" | null | undefined)
          : null
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "40%",
      sortOrder:
        orderBy === "name"
          ? sortBy === "ASC"
            ? "ascend"
            : ("descend" as "descend" | "ascend" | null | undefined)
          : null,
      sorter: true,
      ellipsis: true
      // render: (text: any, record: any, index: any) => {
      //   return <Button type="link">{text}</Button>;
      // }
    },
    {
      title: "Primary Contact",
      dataIndex: "primary_contact",
      width: "20%",
      // key:"description",
      ellipsis: true
    },
    {
      title: "tel",
      dataIndex: "tel"
      // key:"unit"
    },
    {
      title: "fax",
      dataIndex: "fax"
      // key:"price"
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      // key:"enabled",
      sorter: true,
      sortOrder:
        orderBy === "enabled"
          ? sortBy === "ASC"
            ? "ascend"
            : ("descend" as "descend" | "ascend" | null | undefined)
          : null,
      render: (text: any, record: any, index: any) => {
        if (record.enabled) {
          return <CheckOutlined style={{color:'green'}}/>;
        }
      }
    }
  ];

  return (
    <>
      <div>
        <Row>
          <Col span={2}>
            <div
              style={{ marginBottom: 16, verticalAlign: "middle", height: 40 }}
            >
              <Button
                style={{ marginRight: 8, height: 40 }}
                type="primary"
                onClick={() => netvigate("/main/customer/new")}
              >
                NEW
              </Button>{" "}
            </div>
          </Col>
          <Col span={6}>
            {ids.length > 0 && (
              <div
                style={{
                  display: "table",
                  width: "100%"
                }}
              >
                <div style={{ display: "table-cell" }}>
                  <Alert
                    message={`Select ${ids.length} Item(s). `}
                    type="success"
                    closable
                    onClose={() => {
                      setIds([]);
                    }}
                  />
                </div>
                <div style={{ display: "table-cell", width: "20%" }}>
                  <Dropdown overlay={action_menu}>
                    <Button style={{ height: 40 }}>
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            )}
          </Col>
          <Col span={16}>
            {searchField && (
              <Alert
              message="Search Criteria: Name, Primary Contact, Tel, Fax, Email...  Close this message to cancel search mode."
                type="success"
                closable
                afterClose={() =>
                  dispatch(
                    customerReloadAction({
                      ...state.customer,
                      searchField: "",
                      searchContent: ""
                    })
                  )
                }
              />
            )}
          </Col>
        </Row>

        <Table
          rowKey="id"
          tableLayout="fixed"
          onRow={record => {
            return {
              onClick: event => {
                //if ((event.target as any).cellIndex !== 0&&(event.target as any).type !== 'checkbox'&&!((event.target as any).className.indexOf('ant-checkbox')>=0)) {
                // skip checkbox click event.
                if (
                  (event.target as any).cellIndex !== 0 &&
                  !(
                    (event.target as any).className.indexOf("ant-checkbox") >= 0
                  )
                ) {
                  dispatch(
                    customerReloadAction({
                      ...state.customer,
                      currentID: record.id
                    })
                  );
                  netvigate("/main/customer/detail");
                }
              },
              onMouseEnter: event => {
                event.currentTarget.setAttribute("style", "cursor: pointer;");
              }
            };
          }}
          bordered
          columns={columns1}
          dataSource={data?data:[]}
          loading={data?false:true}
          pagination={{
            current: pageNo,
            total: pageTotal,
            pageSize: pageSize,
            defaultPageSize: 8,
            showSizeChanger: true,
            pageSizeOptions: ["8", "16", "24", "30", "40", "50", "100", "200"],
            // onShowSizeChange: (current, pageSize) =>
            //   dispatch(
            //     customerReloadAction({
            //       ...state.customer,
            //       pageSize: pageSize,
            //       pageNo: current
            //     })
            //   )
          }}
          onChange={tableChange}
          style={{ minHeight: 500 }}
          rowSelection={{
            selections: true,
            selectedRowKeys: ids,
            onChange: selectKeys => {
              setIds(selectKeys as []);
            }
          }}
        ></Table>
      </div>
    </>
  );
};
